import React, { useEffect, useState } from "react";
import LoadingScreen from "../../LoadingScreen";
import BaseModal from "./BaseModal";

function ImageModal({ show, onClose, img }) {
  const [beatLoader, setBeatLoader] = useState(false);
  useEffect(() => {
    setBeatLoader(true);
    setTimeout(() => {
      setBeatLoader(false);

      console.log(img);
    }, 2000);
  }, [img]);

  useEffect(() => {
    document.body.style = show ? "overflow: hidden !important" : "";
  });
  return (
    <>
      {show && (
        <BaseModal>
          <div className="Modal-header">
            <button className="btn-close" onClick={onClose}></button>
          </div>
          <div
            style={{
              display: "flex",
              height: "50vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {beatLoader === true ? (
              <LoadingScreen />
            ) : (
              <img
                src={img}
                alt="v-img"
                style={{
                  objectFit: "cover",
                  maxWidth: "75vw",
                  maxHeight: "50vh",
                }}
              />
            )}
          </div>
        </BaseModal>
      )}
    </>
  );
}

export default ImageModal;
