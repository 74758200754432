import React, { useRef, useState, useContext } from "react";
import CategoryModal from "./CategoryModal/CategoryModal";
import Select from "react-select";
import UploadModal from "../../components/Modal/UploadModal/UploadModal";
import {
  options_type,
  options_currency,
  options_certificate,
  typeStyle,
  currencyStyle,
  certificateStyle,
} from "./SelectStyles";
import { BeatLoader } from "react-spinners";
import ItemSlider from "../MyPortfolio/ItemSlider";
import Swal from "sweetalert2";
import FileUploader from "./FileUploader";
import { useStorage } from "../../hooks/useStorage";
import { useDispatch, useSelector } from "react-redux";
import {
  addCategory,
  addCertificateType,
  addDescription,
  addInheritanceComments,
  addInheritanceName,
  addInsuranceCurrency,
  addInsuranceName,
  addInsuranceValue,
  addLocation,
  addPurchasePriceCurrency,
  addPurchasePriceValue,
  addTitle,
  addType,
  removeType,
  selectImage,
  selectInput,
} from "../../redux/uploadSlice";
import { AuthContext } from "../../context/AuthContext";
import TermsModal from "../../components/Modal/PopupModal/TermsModal";
import { useFileCompressor } from "../../hooks/useFileCompressor";

function NewEntry3() {
  const [show, setShow] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [uploadClicked, setUploadClicked] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  // eslint-disable-next-line
  const { isStatus, setIsStatus } = useContext(AuthContext);

  const dispatch = useDispatch();
  const images = useSelector(selectImage);
  const input = useSelector(selectInput);
  console.log(input);
  const receiptRef = useRef(null);
  const certificateRef = useRef(null);

  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  const { uploadToStorage, deleteFromStorage, loading1, loading2, loading3 } =
    useStorage();
  const { heicLoader, fileCompressor } = useFileCompressor();

  const openUpload = (e) => {
    e.preventDefault();
    setUploadClicked(e.target.name);
    setShowUpload(true);
  };

  const uploadFile = async (e) => {
    setIsStatus({
      ...isStatus,
      timeOutAllowed: false,
    });

    setShowUpload(false);

    const file = e.target.files[0];
    console.log(`originalFile size ${file.size / 1024 / 1024} MB`);
    const compressedImage = await fileCompressor(file);

    await uploadToStorage(
      compressedImage,
      e.target.name.length > 0 ? e.target.name : uploadClicked
    );
    console.log("uploaded image");
  };

  const deleteImage = (fileName, type, removeIndex) => {
    Swal.fire({
      title: "Delete photo?",
      text: "This action cannot be undone",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#808080",
      cancelButtonColor: "#B3995B",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteFromStorage(fileName, type, removeIndex);
        Swal.fire({
          icon: "success",
          title: "Photo has been deleted",
          showConfirmButton: false,
          timer: 1000,
        });
      }
    });
  };

  const categories = [
    "Rings",
    "Earrings",
    "Pendants / Necklaces",
    "Bracelets / Bangles",
    "Brooches",
    "Gems",
    "Others",
  ];

  return (
    <div>
      <UploadModal
        showUpload={showUpload}
        setShowUpload={setShowUpload}
        input={input}
        uploadFile={uploadFile}
      />

      <ItemSlider>
        {images.map((img, index) => {
          return (
            <div
              name="items"
              className="itemslider-container"
              onClick={(e) => {
                deleteImage(img.fileName, e.target.name, index);
              }}
            >
              {loading1 ? (
                <BeatLoader size={8} />
              ) : (
                <img
                  src={img.fileUrl}
                  name="items"
                  style={{
                    minWidth: "150px",
                    maxWidth: "250px",
                    maxHeight: "200px",
                    objectFit: "cover",
                  }}
                  alt="img"
                />
              )}
            </div>
          );
        })}
        <FileUploader
          input={input}
          setShowUpload={setShowUpload}
          loading={loading1}
          uploadFile={uploadFile}
          openUpload={openUpload}
          heicLoader={heicLoader}
        />
      </ItemSlider>
      <p style={{ margin: "0 20px 15px" }}>Tap photos to delete or replace.</p>
      <div>
        <div className="accordion" onClick={() => setShow(true)}>
          <div className="accordion-item">
            <div className="accordion-header">
              <div className="accordion-button collapsed ">
                <div>{input.category}</div>
                <div className="accordion-button-icon">
                  <img src="/fixed/Dropdown.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CategoryModal
          show={show}
          onClose={() => setShow(false)}
          header="Category"
          categories={categories}
          setCategory={(cat) => dispatch(addCategory(cat))}
        />
      </div>
      <div className="compulsory-text ">
        <p>* indicates compulsory field, all others optional.</p>
      </div>
      <div className="form">
        <div className="form-container">
          <label>
            Title of Piece
            <div className="asterisk">*</div>
            <input
              autocomplete="off"
              required="required"
              name="title"
              type="text"
              onChange={(e) => {
                dispatch(addTitle(e.target.value));
              }}
              value={input.title}
              placeholder="Eg. “The Blue Souffle” or “Diamond Ring”"
            />
            <p>{`${30 - input.title?.length} / ${30} characters left`}</p>
          </label>
        </div>
        <div className="form-container">
          <div className="selected-type">
            {input.type.map((type, index) => {
              return (
                <span className="chip">
                  {type}
                  <img
                    class="icon_cancel closeIcon"
                    alt="img"
                    src="data:image/svg+xml,%3Csvg%20height%3D%22512px%22%20id%3D%22Layer_1%22%20style%3D%22enable-background%3Anew%200%200%20512%20512%3B%22%20version%3D%221.1%22%20viewBox%3D%220%200%20512%20512%22%20width%3D%22512px%22%20xml%3Aspace%3D%22preserve%22%20%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20%20%20%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%20%20%20%20%3Cstyle%20type%3D%22text%2Fcss%22%3E%20%20%20%20%20%20%20%20.st0%7B%20%20%20%20%20%20%20%20%20%20%20%20fill%3A%23fff%3B%20%20%20%20%20%20%20%20%7D%20%3C%2Fstyle%3E%20%20%20%20%3Cpath%20class%3D%22st0%22%20d%3D%22M443.6%2C387.1L312.4%2C255.4l131.5-130c5.4-5.4%2C5.4-14.2%2C0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7%2C0-7.2%2C1.5-9.8%2C4%20%20L256%2C197.8L124.9%2C68.3c-2.6-2.6-6.1-4-9.8-4c-3.7%2C0-7.2%2C1.5-9.8%2C4L68%2C105.9c-5.4%2C5.4-5.4%2C14.2%2C0%2C19.6l131.5%2C130L68.4%2C387.1%20%20c-2.6%2C2.6-4.1%2C6.1-4.1%2C9.8c0%2C3.7%2C1.4%2C7.2%2C4.1%2C9.8l37.4%2C37.6c2.7%2C2.7%2C6.2%2C4.1%2C9.8%2C4.1c3.5%2C0%2C7.1-1.3%2C9.8-4.1L256%2C313.1l130.7%2C131.1%20%20c2.7%2C2.7%2C6.2%2C4.1%2C9.8%2C4.1c3.5%2C0%2C7.1-1.3%2C9.8-4.1l37.4-37.6c2.6-2.6%2C4.1-6.1%2C4.1-9.8C447.7%2C393.2%2C446.2%2C389.7%2C443.6%2C387.1z%22%2F%3E%3C%2Fsvg%3E"
                    onClick={() => dispatch(removeType(index))}
                  />
                </span>
              );
            })}
          </div>
          <label>Diamonds / Gemstones </label>
          <Select
            styles={typeStyle}
            value={input.type}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Eg. Diamond, Ruby, Sapphire"
            options={options_type}
            onChange={(item) => {
              dispatch(addType(item.label));
            }}
          />
          <p>Input type of stone and select from the list</p>
        </div>
        <div className="form-container" style={{ marginTop: "40px" }}>
          <label>
            Description
            <textarea
              autoComplete="off"
              rows="4"
              name="description"
              type="text"
              onChange={(e) => dispatch(addDescription(e.target.value))}
              value={input.description}
              placeholder="Eg. “Birthday present to myself”, “Gift from Grandma”, or any other details you want to include for yourself."
            />
          </label>
        </div>
        <div className="form-container" style={{ marginTop: "38px" }}>
          <label>
            Location
            <input
              autocomplete="off"
              name="location"
              type="text"
              onChange={(e) => dispatch(addLocation(e.target.value))}
              value={input.location}
              placeholder="Eg. Home, bank, safe"
            />
          </label>
        </div>
        <div className="form-container" style={{ marginTop: "55px" }}>
          <label>
            Purchase Price
            <p>Historical Value</p>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Select
                styles={currencyStyle}
                className="basic-single"
                value={options_currency.filter(
                  (option) => option.label === input.purchase_price.currency
                )}
                classNamePrefix="select"
                placeholder="SGD $"
                options={options_currency}
                onChange={(currency) => {
                  dispatch(addPurchasePriceCurrency(currency.label));
                }}
              />
              <span style={{ margin: "8px" }}></span>
              <input
                autoComplete="off"
                name="purchase_price"
                type="number"
                min="0"
                onKeyDown={(e) => {
                  if (["e", "+", "-"].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  dispatch(addPurchasePriceValue(e.target.value));
                }}
                value={input.purchase_price.value}
              />
            </div>
          </label>
        </div>

        {/*--------------------------- Receipts & Documents ------- ---------------------*/}
        <div className="form-container" style={{ marginTop: "20px" }}>
          <label>
            Receipts & Documents
            <p>Upload receipts or any other relevant documents.</p>
            <p>(Files accepted: .jpg, .png, .pdf, .doc, .heic, .heif)</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="upload-btn"
                onClick={(e) => {
                  e.preventDefault();

                  setIsStatus({
                    ...isStatus,
                    timeOutAllowed: false,
                  });

                  if (isIos()) {
                    receiptRef.current.click();
                  } else {
                    // If on Chrome, dont show uploadModal
                    if (
                      window.navigator.userAgent.match(/chrome|chromium|crios/i)
                    ) {
                      receiptRef.current.click();
                    } else {
                      openUpload(e);
                    }
                  }
                }}
                name="receipts"
              >
                {loading2 ? <BeatLoader size={8} /> : "Upload"}
              </button>
            </div>
          </label>
          <input
            ref={receiptRef}
            type="file"
            name="receipts"
            hidden
            onChange={uploadFile}
          />
          <div>
            {input.receipts.map((receipt, index) => (
              <div key={{ index }} style={{ margin: "10px" }}>
                <img src="/fixed/Document.svg" alt="fixedDocument" />
                <span style={{ marginLeft: "10px" }}>{receipt.fileName}</span>

                <img
                  src="/fixed/CloseButton.svg"
                  name="receipts"
                  className="picture-btn"
                  alt="pictureBtn"
                  onClick={(e) => {
                    e.preventDefault();

                    setUploadClicked(e.target.name);
                    deleteFromStorage(receipt.fileName, e.target.name, index);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        {/*--------------------------- Certificate ----------------------------*/}
        <div className="form-container">
          <label>Certificate Type</label>
          <Select
            styles={certificateStyle}
            value={options_certificate.filter(
              (option) => option.label === input.certificate.type
            )}
            options={options_certificate}
            className="basic-single"
            classNamePrefix="select"
            placeholder="If any"
            onChange={(item) => {
              dispatch(addCertificateType(item.label));
            }}
          />
          <div style={{ margin: "20px 0 0" }}>
            <label>Certificate</label>
            <p>
              Upload a photo or digital copy of your item’s certificate. (Files
              accepted: .jpg, .png, .pdf, .doc, .heic, .heif)
            </p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                className="upload-btn"
                name="certificate"
                onClick={(e) => {
                  e.preventDefault();
                  if (isIos()) {
                    certificateRef.current.click();
                  } else {
                    // If on Chrome, dont show uploadModal
                    if (
                      window.navigator.userAgent.match(/chrome|chromium|crios/i)
                    ) {
                      certificateRef.current.click();
                    } else {
                      openUpload(e);
                    }
                  }
                }}
              >
                {loading3 === true ? <BeatLoader size={8} /> : "Upload"}
              </button>
            </div>
            <input
              ref={certificateRef}
              type="file"
              name="certificate"
              hidden
              onChange={uploadFile}
            />
            <div>
              {input.certificate.img.map((cert, index) => (
                <div key={{ index }} style={{ margin: "10px" }}>
                  <img src="/fixed/Document.svg" alt="fixedDocument" />
                  <span style={{ marginLeft: "10px" }}>{cert.fileName}</span>
                  <img
                    src="/fixed/CloseButton.svg"
                    className="picture-btn"
                    name="certificate"
                    alt="picBtn"
                    onClick={(e) => {
                      e.preventDefault();
                      deleteFromStorage(cert.fileName, e.target.name, index);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        {/*------------------ start of footer accordion --------------------------- */}
        <div className="form-footer">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
                onClick={(e) => {
                  e.preventDefault();
                  setExpanded(!expanded);
                }}
              >
                <img
                  src={expanded ? "/fixed/Minus.svg" : "/fixed/Plus.svg"}
                  alt="img"
                />
                <span>Insurance Details</span>
              </button>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
              >
                <div class="accordion-body">
                  <div className="form-container">
                    <label>
                      Insurer
                      <input
                        autoComplete="off"
                        name="name"
                        type="text"
                        onChange={(e) =>
                          dispatch(addInsuranceName(e.target.value))
                        }
                        value={input.insurance.name}
                        placeholder="Name of Insurer, eg. Chubbs"
                      />
                    </label>
                  </div>
                  <div className="form-container">
                    <label>
                      Insured Value
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Select
                          styles={currencyStyle}
                          className="basic-single"
                          value={options_currency.filter(
                            (option) =>
                              option.label === input.insurance.currency
                          )}
                          classNamePrefix="select"
                          placeholder="SGD $"
                          options={options_currency}
                          onChange={(currency) => {
                            dispatch(addInsuranceCurrency(currency.label));
                          }}
                        />
                        <span style={{ margin: "8px" }}></span>
                        <input
                          name="value"
                          type="number"
                          min="0"
                          onKeyDown={(e) => {
                            if (["e", "+", "-"].includes(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          onChange={(e) =>
                            dispatch(addInsuranceValue(e.target.value))
                          }
                          value={input.insurance.value}
                          placeholder="Insured Value"
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
                onClick={(e) => {
                  e.preventDefault();
                  setExpanded2(!expanded2);
                }}
              >
                <img
                  src={expanded2 ? "/fixed/Minus.svg" : "/fixed/Plus.svg"}
                  alt="expanded"
                />
                <span>Inheritance Planning</span>
              </button>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
              >
                <div class="accordion-body">
                  <div className="form-container">
                    <label>
                      For:
                      <input
                        autocomplete="off"
                        name="name"
                        type="text"
                        onChange={(e) =>
                          dispatch(addInheritanceName(e.target.value))
                        }
                        value={input.inheritance.name}
                        placeholder="Recipient's Name"
                      />
                    </label>
                  </div>
                  <div className="form-container">
                    <label>
                      Notes
                      <input
                        type="text"
                        name="comments"
                        placeholder="Additional Comments"
                        value={input.inheritance.comments}
                        onChange={(e) =>
                          dispatch(addInheritanceComments(e.target.value))
                        }
                      />
                    </label>
                  </div>

                  <div className="tc" onClick={() => setOpenModal(true)}>
                    Terms and conditions apply
                  </div>
                </div>
              </div>

              <TermsModal
                show={openModal}
                onClose={() => setOpenModal(false)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewEntry3;
