import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import store from "./redux/store";
import { Provider } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
const muiTheme = createTheme();
console.log(process.env.REACT_APP_URL);
ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={muiTheme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
