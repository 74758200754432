import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uid: null,
  category: "",
  title: "",
  img: [],
  type: [],
  description: "",
  location: "",
  assessed: false,
  purchase_price: {
    currency: "SGD $",
    value: null,
  },
  receipts: [],
  certificate: {
    type: "",
    img: [],
  },
  insurance: {
    name: "",
    value: null,
    currency: "SGD $",
  },
  inheritance: {
    name: "",
    comments: "",
  },
};
const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    addCategory(state, action) {
      state.category = action.payload;
    },
    addTitle(state, action) {
      state.title = action.payload.slice(0, 30);
    },
    addImage(state, action) {
      state.img.push(action.payload);
    },
    removeImage(state, action) {
      state.img = state.img.filter((item, index) => index !== action.payload);
    },
    addType(state, action) {
      !state.type.includes(action.payload) && state.type.push(action.payload);
    },
    removeType(state, action) {
      state.type.splice(action.payload, 1);
    },
    addDescription(state, action) {
      state.description = action.payload;
    },
    addLocation(state, action) {
      state.location = action.payload;
    },
    isAccessed(state, action) {
      state.assessed = action.payload;
    },
    addPurchasePriceCurrency(state, action) {
      state.purchase_price.currency = action.payload;
    },
    addPurchasePriceValue(state, action) {
      state.purchase_price.value = action.payload;
    },
    addReceipt(state, action) {
      state.receipts.push(action.payload);
    },
    removeReceipt(state, action) {
      state.receipts.splice(action.payload);
    },
    addCertificateType(state, action) {
      state.certificate.type = action.payload;
    },
    addCertificate(state, action) {
      state.certificate.img.push(action.payload);
    },
    removeCertificate(state, action) {
      state.certificate.img.splice(action.payload, 1);
    },
    addInsuranceName(state, action) {
      state.insurance.name = action.payload;
    },
    addInsuranceCurrency(state, action) {
      state.insurance.currency = action.payload;
    },
    addInsuranceValue(state, action) {
      state.insurance.value = action.payload;
    },
    addInheritanceName(state, action) {
      state.inheritance.name = action.payload;
    },
    addInheritanceComments(state, action) {
      state.inheritance.comments = action.payload;
    },
    resetInput: () => initialState,
    updateInput(state, action) {
      return action.payload;
    },
    updateUser(state, action) {
      state.uid = action.payload;
    },
  },
});

export const {
  addCategory,
  addTitle,
  addImage,
  removeImage,
  addType,
  removeType,
  addDescription,
  addLocation,
  isAccessed,
  addPurchasePriceCurrency,
  addPurchasePriceValue,
  addReceipt,
  removeReceipt,
  addCertificateType,
  addCertificate,
  removeCertificate,
  addInsuranceName,
  addInsuranceCurrency,
  addInsuranceValue,
  addInheritanceName,
  addInheritanceComments,
  resetInput,
  updateInput,
  updateUser,
} = uploadSlice.actions;

export const selectInput = (state) => state.upload;

export const selectImage = (state) => state.upload.img;
export default uploadSlice.reducer;
