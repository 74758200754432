import { useEffect, useState } from "react";
import Header from "../../components/Header";
import Carousell from "../../components/Carousell";
import MenuButton from "../../components/MenuButton";
import Menu from "../../components/Menu";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
import { Link } from "react-router-dom";
import ItemContainer from "../MyPortfolio/ItemContainer";
import { BeatLoader } from "react-spinners";
import { Box, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export default function Homepage() {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [color] = useState("#b3995b");

  const handleMouseDown = () => {
    setVisible(!visible);
  };

  const user = JSON.parse(localStorage.getItem("user"));

  const fetchItems = async () => {
    setLoading(true);

    onSnapshot(doc(db, "users", user.uid), (res) => {
      if (res.data().items.length > 0) {
        res.data().items.forEach(async (item) => {
          const docSnap = await getDoc(doc(db, "items", item));

          setItems((prev) => [...prev, docSnap.data()]);

          setLoading(false);
        });
      } else {
        setItems([]);

        setLoading(false);
      }
    });
  };

  useEffect(() => {
    fetchItems();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Header
        title="AUCOVA"
        leftButton={<MenuButton handleMouseDown={handleMouseDown} />}
      />
      <Menu menuVisibility={visible} handleMouseDown={handleMouseDown} />

      <Carousell />

      <div className="landing-container">
        <div className="about-header">
          <h2>My Portfolio</h2>
        </div>

        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <BeatLoader color={color} />
          </div>
        ) : (
          <div>
            {items.length > 0 ? (
              <>
                <ItemContainer items={items} />
                <Link
                  className="position-absolute end-0 "
                  style={{
                    marginRight: "15px",
                    borderBottom: "1px solid black",
                    lineHeight: "16px",
                  }}
                  to="/portfolio"
                >
                  <h5>See All</h5>
                </Link>
              </>
            ) : (
              <div className="item-container">
                <div className="myPortfolioDiv">
                  <div className="item-container-text">
                    <h5 style={{ fontFamily: "Avenir Medium" }}>
                      Create your Jewellery Portfolio now!
                    </h5>
                  </div>
                  <Box>
                    <p style={{ fontStyle: "italic", textAlign: "center" }}>
                      All information is kept secure & private until published
                      to Public showcase.​
                    </p>

                    <Link to="/portfolio/newentry">
                      <Fab className="item-container button" aria-label="add">
                        <AddIcon />
                      </Fab>
                    </Link>
                  </Box>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
