import React, { useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";

const LoadingScreen = () => {
  const [color] = useState("#b3995b");

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <BeatLoader color={color} />
      </div>
    </>
  );
};

export default LoadingScreen;
