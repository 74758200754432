import { sendPasswordResetEmail } from "firebase/auth";
import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import { auth } from "../../firebase";
import Swal from "sweetalert2";

function ForgotPassword({ setState }) {
	const [email, setEmail] = useState("");
	const [beatLoader, setBeatLoader] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();
		setBeatLoader(true);

		sendPasswordResetEmail(auth, email)
			.then(() => setState(4))
			.catch((err) => {
				setBeatLoader(false);
				Swal.fire({
					icon: "error",
					title: err.message,
				});
			});
	};
	return (
		<>
			<div className="intro-header">
				<div
					style={{
						height: "15vh",
						justifyContent: "center",
						display: "flex",
						alignItems: "center",
					}}
				>
					<img src="/LogoStacked.png" alt="" width="200px" />
				</div>

				{/* <h2>Welcome to AUCOVA</h2>
        <h5>Jewellery Asset Management</h5> */}
			</div>
			<div className="password-reset">
				<b>Password Reset</b>
			</div>
			<div className="password-reset-textBox">
				<h5>
					Please enter the email address linked to your account to reset your
					password.
				</h5>
			</div>
			<div className="auth-container">
				<form onSubmit={handleSubmit}>
					<div className="auth">
						<input
							type="text"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<label className={email ? "active" : ""}>Email</label>
					</div>
					<div className="forgot-password"></div>
					<button className="intro-button btn-positive">
						{beatLoader ? <BeatLoader size={8} /> : "Reset Password"}
					</button>
				</form>
				<div className="auth-footer">
					<span>Back to </span>
					<span
						style={{
							textDecoration: "underline",
							color: "var(--primary)",
							paddingLeft: "5px",
						}}
						onClick={() => setState(1)}
					>
						Log In
					</span>
				</div>
			</div>
		</>
	);
}

export default ForgotPassword;
