import React, { useEffect, useContext } from "react";
import { auth } from "../../firebase";
import Swal from "sweetalert2";
import Header from "../../components/Header";
import axios from "axios";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import AnimateImg from "../../components/AnimateImg";
// import { useDispatch } from "react-redux";
// import { signOut, getAuth } from "firebase/auth";

const Reauth = () => {
  // eslint-disable-next-line
  const [userCred, setUserCred] = useLocalStorage("user", null);
  // eslint-disable-next-line
  const { isStatus, setIsStatus } = useContext(AuthContext);
  // eslint-disable-next-line
  const [data, setData] = useLocalStorage("authData", null);

  useEffect(() => {
    console.log(auth);
  });

  const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const user = getAuth().currentUser;

  const skip = async () => {
    Swal.fire({
      title: "Skip setting up of biometric?",
      icon: "info",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes",
      confirmButtonColor: "#B3995B",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const token = await auth.currentUser.getIdToken();
        axios
          .post(
            `${process.env.REACT_APP_URL}/${process.env.REACT_APP_GET_ID}`,
            {
              email: auth.currentUser.email,
              uid: auth.currentUser.uid,
              Authid: "null",
              AuthrawId: "null",
              biometricEnabled: false,
            },
            {
              headers: { AuthToken: token },
            }
          )
          .then((res) => {
            console.log(res);
            setIsStatus({
              code: "BIOMETRIC_DISABLED",
              timeOutAllowed: res.data.biometricEnabled,
              docRefID: res.data.docRefID,
            });

            Swal.fire({
              icon: "success",
              title: "You've successfully created an account.",
              showConfirmButton: false,
              timer: 1500,
            });

            navigate("/");
          });
      } else {
        setIsStatus({
          ...isStatus,
          code: "BIOMETRIC_NOT_AUTHENTICATED",
        });
      }
    });
  };

  const binToStr = (bin) => {
    return btoa(
      new Uint8Array(bin).reduce((s, byte) => s + String.fromCharCode(byte), "")
    );
  };

  // ENABLE BIOMETRIC IN SETTINGS
  const registerUpdate = async () => {
    console.log(isStatus.docRefID);

    const token = await auth.currentUser.getIdToken();
    // delete existing data from firestore
    return axios
      .post(
        `${process.env.REACT_APP_URL}/${process.env.REACT_APP_DELETE_DATA}`,
        {
          email: auth.currentUser.email,
          uid: auth.currentUser.uid,
          docRefID: isStatus.docRefID,
        },
        {
          headers: { AuthToken: token },
        }
      )
      .then(async (res) => {
        console.log(res);

        if (res.status === 200) {
          return axios
            .post(
              `${process.env.REACT_APP_URL}/${process.env.REACT_APP_BIOMETRIC}`,
              {
                email: auth.currentUser.email,
                uid: auth.currentUser.uid,
              },
              {
                headers: { AuthToken: token },
              }
            )
            .then(async (res) => {
              console.log(res.data); // PublicKey Object received from server

              // Challenge
              let arr = [];
              // eslint-disable-next-line
              for (const [key, value] of Object.entries(res.data.challenge)) {
                //console.log(`${key}: ${value}`);
                arr.push(value);
              }
              const z = new Uint8Array(arr);
              // console.log(z);

              // id
              let arr2 = [];
              let id = res.data.user.id;
              console.log(id);
              for (const [key, value] of Object.entries(id)) {
                console.log(`${key}: ${value}`);
                arr2.push(value);
              }
              const x = new Uint8Array(arr2);
              console.log(x);

              let publicKey = {
                ...res.data,
                challenge: z,
                user: {
                  ...res.data.user,
                  id: x,
                },
              };

              console.log(publicKey);

              await navigator.credentials
                .create({
                  publicKey: publicKey,
                })
                .then((res) => {
                  // PUBLIC KEY CREATED FROM WEBAUTHN
                  console.log(res);
                  setData({
                    status: "id and rawId Created",
                    Authid: res.id,
                    AuthrawId: binToStr(res.rawId),
                  });

                  axios
                    .post(
                      `${process.env.REACT_APP_URL}/${process.env.REACT_APP_GET_ID}`,
                      {
                        email: auth.currentUser.email,
                        uid: auth.currentUser.uid,
                        Authid: res.id,
                        AuthrawId: binToStr(res.rawId),
                        biometricEnabled: true,
                      },
                      {
                        headers: { AuthToken: token },
                      }
                    )
                    .then((res) => {
                      console.log(res);
                      setIsStatus({
                        code: "BIOMETRIC_AUTHENTICATED",
                        timeOutAllowed: true,
                      });

                      Swal.fire({
                        icon: "success",
                        title: "Your Face/TouchID has been enabled!",
                        showConfirmButton: false,
                        timer: 1500,
                      });
                      navigate("/");
                    });
                })
                .catch((err) => {
                  console.log(err);
                  Swal.fire({
                    icon: "info",
                    title:
                      "Enable biometric authentication to secure your data.",
                    confirmButtonColor: "#B3995B",
                  });
                });
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const register = async () => {
    const token = await auth.currentUser.getIdToken();

    return axios
      .post(
        `${process.env.REACT_APP_URL}/${process.env.REACT_APP_BIOMETRIC}`,
        {
          email: auth.currentUser.email,
          uid: auth.currentUser.uid,
        },
        {
          headers: { AuthToken: token },
        }
      )
      .then(async (res) => {
        console.log(res.data); // PublicKey Object received from server

        // Challenge
        let arr = [];
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(res.data.challenge)) {
          //console.log(`${key}: ${value}`);
          arr.push(value);
        }
        const z = new Uint8Array(arr);
        // console.log(z);

        // id
        let arr2 = [];
        let id = res.data.user.id;
        console.log(id);
        for (const [key, value] of Object.entries(id)) {
          console.log(`${key}: ${value}`);
          arr2.push(value);
        }
        const x = new Uint8Array(arr2);
        console.log(x);

        let publicKey = {
          ...res.data,
          challenge: z,
          user: {
            ...res.data.user,
            id: x,
          },
        };

        console.log(publicKey);

        await navigator.credentials
          .create({
            publicKey: publicKey,
          })
          .then((res) => {
            // PUBLIC KEY CREATED FROM WEBAUTHN
            console.log(res);
            setData({
              status: "id and rawId Created",
              Authid: res.id,
              AuthrawId: binToStr(res.rawId),
            });

            axios
              .post(
                `${process.env.REACT_APP_URL}/${process.env.REACT_APP_GET_ID}`,
                {
                  email: auth.currentUser.email,
                  uid: auth.currentUser.uid,
                  Authid: res.id,
                  AuthrawId: binToStr(res.rawId),
                  biometricEnabled: true,
                },
                {
                  headers: { AuthToken: token },
                }
              )
              .then((res) => {
                console.log(res);
                setIsStatus({
                  code: "BIOMETRIC_AUTHENTICATED",
                  timeOutAllowed: true,
                });

                Swal.fire({
                  icon: "success",
                  title: "Your Face/TouchID has been enabled!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                navigate("/");
              });
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              icon: "info",
              title: "Enable biometric authentication to secure your data.",
              confirmButtonColor: "#B3995B",
            });
          });
      });
  };

  const unlock = async () => {
    const token = await auth.currentUser.getIdToken();
    console.log(token);

    return axios
      .post(
        `${process.env.REACT_APP_URL}/${process.env.REACT_APP_VALIDATE_KEYS}`,
        {
          uid: userCred.uid,
          email: userCred.email,
        },
        {
          headers: { AuthToken: token },
        }
      )
      .then(async (res) => {
        console.log(res.data);

        let arr = [];
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(res.data.challenge)) {
          //console.log(`${key}: ${value}`);
          arr.push(value);
        }
        const z = new Uint8Array(arr);
        // console.log(z);

        let arr2 = [];
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(
          res.data.allowCredentials[0].id
        )) {
          //console.log(`${key}: ${value}`);
          arr2.push(value);
        }
        const x = new Uint8Array(arr2);
        // console.log(z);

        let publicKey = {
          ...res.data,
          allowCredentials: [
            {
              ...res.data.allowCredentials[0],
              id: x,
            },
          ],
          challenge: z,
        };
        console.log(publicKey);

        await navigator.credentials
          .get({
            publicKey: publicKey,
          })
          .then(async (res) => {
            console.log(res);
            const extractedData = {
              id: res.id,
              rawId: binToStr(res.rawId),
              clientDataJSON: binToStr(res.response.clientDataJSON),
            };

            return await axios
              .post(
                `${process.env.REACT_APP_URL}/${process.env.REACT_APP_VALIDATE_CHECKS}`,
                extractedData,
                {
                  headers: { AuthToken: token },
                }
              )
              .then((res) => {
                console.log(res);
                // eslint-disable-next-line
                if (res.status == 200) {
                  setIsStatus({
                    code: "BIOMETRIC_AUTHENTICATED",
                    timeOutAllowed: true,
                  });
                  console.log("AUTHORISED");

                  Swal.fire({
                    icon: "success",
                    title: `Welcome back, ${userCred.username}!`,
                    showConfirmButton: false,
                    timer: 1500,
                  });

                  navigate("/");
                }
              });
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              icon: "info",
              title: "Unlock the AUCOVA app with Face/Touch ID",
              confirmButtonColor: "#B3995B",
            });
          });
      });
  };

  return (
    <>
      <div>
        <Header title="AUCOVA" />
      </div>

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "75px" }}
      >
        <span style={{ marginBottom: "55px" }}>
          <AnimateImg />
        </span>
      </div>

      <div style={{ height: "70vh" }}>
        {isStatus.code === "BIOMETRIC_NOT_AUTHENTICATED" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h2>Welcome to AUCOVA,</h2>
            <h3 style={{ marginTop: "8px", marginBottom: "18px" }}>
              {userCred.username}!
            </h3>

            <img src="/fixed/Line.svg" alt="" />

            <span
              style={{
                marginTop: "25px",
                marginLeft: "20px",
                marginRight: "20px",
                textAlign: "center",
                color: "#000",
                opacity: "0.4",
              }}
            >
              <p style={{ fontSize: "15px" }}>
                As an added layer of security, please enable Face/Touch ID
                authentication to log into AUCOVA.
              </p>
            </span>

            <span
              style={{
                marginTop: "25px",
                marginLeft: "20px",
                marginRight: "20px",
                textAlign: "center",
                color: "#000",
                opacity: "0.4",
              }}
            >
              <p style={{ fontSize: "15px" }}>
                You can always disable it later under <br />
                Settings {">"} Security
              </p>
            </span>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "end",
                height: "35vh",
                flexDirection: "column",
              }}
            >
              {!isStatus.docRefID ? (
                <button className="intro-button btn-negative" onClick={skip}>
                  Skip
                </button>
              ) : null}
              <div style={{ margin: "8px 0 " }}></div>
              {isStatus.updateData === true ? (
                <button
                  className="intro-button btn-positive"
                  onClick={registerUpdate}
                >
                  Enable
                </button>
              ) : (
                <button
                  className="intro-button btn-positive"
                  onClick={register}
                >
                  Enable
                </button>
              )}
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h2>Welcome back,</h2>
            <h3 style={{ marginTop: "8px", marginBottom: "18px" }}>
              {userCred.username}!
            </h3>

            <img src="/fixed/Line.svg" alt="" />

            <span
              style={{
                marginTop: "35px",
                marginLeft: "40px",
                marginRight: "40px",
                textAlign: "center",
                color: "#000",
                opacity: "0.4",
              }}
            >
              <p style={{ fontSize: "15px" }}>
                To keep your data secure, you were locked out due to inactivity.
                To access your portfolio, please authenticate with your
                registered biometrics. (FaceID or TouchID)
              </p>
            </span>

            <div className="setup-footer">
              <button className="intro-button btn-positive" onClick={unlock}>
                Unlock
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Reauth;
