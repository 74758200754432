import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { Backdrop } from "@mui/material";
import { Box } from "@mui/system";

const ITEM_HEIGHT = 48;

const LongMenuItem = ({ deleteEntry, editEntry, showMenu, setShowMenu }) => {
  return (
    <>
      <Backdrop
        style={{ zIndex: "99", color: "fff" }}
        open={showMenu}
        onClick={() => setShowMenu(false)}
      >
        <Menu
          inputProps={{ MenuProps: { disableScrollLock: true } }}
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 0, horizontal: "right" }}
          transformOrigin={{ vertical: 0, horizontal: "right" }}
          open={showMenu}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 7,
              width: "20ch",
              transform: "translateX(16px) translateY(-16px)",
              fontFamily: "Avenir Regular",
              fontSize: "16px",
            },
          }}
        >
          <MenuItem key="edit" onClick={editEntry}>
            <h5>Edit</h5>
          </MenuItem>
          <MenuItem
            key="delete"
            onClick={() => {
              setShowMenu(false);
              deleteEntry();
            }}
          >
            <h5> Delete</h5>
          </MenuItem>
          <Box
            className="css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root"
            style={{
              backgroundColor: "#b3995b",
              fontFamily: "Avenir Bold",
              color: "white",
            }}
          >
            Publish To:
          </Box>
          <MenuItem key="private">
            <h5>Private Only</h5>
          </MenuItem>
          <MenuItem style={{ whiteSpace: "normal" }} key="public" disabled>
            <h5>Public Showcase (Coming soon)</h5>
          </MenuItem>
          <MenuItem style={{ whiteSpace: "normal" }} key="marketplace" disabled>
            <h5> Marketplace (Coming soon)</h5>
          </MenuItem>
        </Menu>
      </Backdrop>
    </>
  );
};
export default LongMenuItem;
