import React, { useState, useRef, useContext } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import { db, storage } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { getAuth, updateProfile } from "firebase/auth";
import Swal from "sweetalert2";
import { BeatLoader } from "react-spinners";
import { AuthContext } from "../../context/AuthContext";
import { useFileCompressor } from "../../hooks/useFileCompressor";

function SetUp() {
  const [visible, setVisible] = useState(false);

  const [profilePic, setProfilePic] = useState(null);
  const [username, setUsername] = useState("");
  const [beatLoader, setBeatLoader] = useState("");
  const [imageUploader, setImageUploader] = useState(false);

  // eslint-disable-next-line

  const handleMouseDown = () => {
    setVisible(!visible);
  };

  // eslint-disable-next-line no-unused-vars
  const { userCred, setUserCred } = useContext(AuthContext);
  // eslint-disable-next-line
  const { isStatus, setIsStatus } = useContext(AuthContext);

  const user = getAuth().currentUser;

  const navigate = useNavigate();
  const profileRef = useRef(null);
  //console.log(profilePic);
  const { fileCompressor } = useFileCompressor();
  const addProfilePic = async (e) => {
    setImageUploader(true);
    console.log("UPLOADING......");
    const file = e.target.files[0];

    if (profilePic) {
      setProfilePic(null);
      const deleteImageRef = ref(storage, `profilePic/${profilePic.name}`);
      deleteObject(deleteImageRef);
    }

    const compressedImage = await fileCompressor(file);
    const imagesRef = ref(storage, `profilePic/${compressedImage.name}`);

    uploadBytes(imagesRef, compressedImage)
      .then(() => getDownloadURL(imagesRef))
      .then((url) => {
        setProfilePic({
          name: compressedImage.name,
          url: url,
        });
        setImageUploader(false);
        console.log("heic uploaded.");
      })
      .catch((err) => console.log(err.message));
  };

  console.log(profilePic);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBeatLoader(true);

    const userFeedback = [
      "Username cannot be blank",
      "Username cannot contain special characters",
      "Account successfully created",
      "Please upload a photo",
    ];

    //eslint-disable-next-line
    const invalidChar = /[!@#$%^&*()+\-=\[\]{};':"\\|,<>\/? ]+/;

    //eslint-disable-next-line
    if (username == "") {
      setBeatLoader(false);
      Swal.fire({
        icon: "error",
        title: userFeedback[0],
        showConfirmButton: false,
        timer: 1500,
      });
    } else if (username.match(invalidChar)) {
      setBeatLoader(false);
      Swal.fire({
        icon: "error",
        title: userFeedback[1],
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      navigate("/loading");

      setTimeout(() => {
        setUsername(username);
        setUserCred((prev) => {
          return {
            ...prev,
            username: username,
            // verificationStatus: true,
          };
        });
        updateProfile(user, {
          displayName: username,
        })
          .then(() => {
            console.log("firebase displayName updated");
            setBeatLoader(false);

            setIsStatus({
              code: "BIOMETRIC_NOT_AUTHENTICATED",
              timeOutAllowed: true,
            });

            navigate("/reauth");
          })
          .catch((err) => {
            setBeatLoader(false);
            console.log(err.message);
          })
          .then(() => {
            setDoc(doc(db, "users", user.uid), {
              username: username,
              email: user.email,
              profilePic: profilePic
                ? {
                    ...profilePic,
                  }
                : null,
              fullname: "",
              uid: user.uid,
              subscription: false,
              items: [],
            });

            console.log("User collected created.");
          });
      }, 1500);
    }
  };

  return (
    <>
      <Header title="AUCOVA" />
      <Menu menuVisibility={visible} handleMouseDown={handleMouseDown} />
      <form onSubmit={handleSubmit}>
        <div className="setup-container">
          <div className="intro-header setup">
            <h2>Profile Creation</h2>
            <div className="setup-profile">
              {profilePic && (
                <img src={profilePic.url} alt="" className="profile-pic" />
              )}

              {!profilePic && imageUploader === false && (
                <img src="/fixed/DefaultProfile.svg" alt="" />
              )}

              {imageUploader === true ? <BeatLoader size={8} /> : ""}
            </div>
            <p
              style={{ textDecoration: "underline" }}
              onClick={() => profileRef.current.click()}
            >
              Upload profile picture
            </p>
            <input
              type="file"
              ref={profileRef}
              hidden
              onChange={addProfilePic}
            />
          </div>
          <div className="auth">
            <input
              type="text"
              placeholder="Username"
              value={username}
              name={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <h6>
              Usernames can only use letters, numbers, underscores and periods.
            </h6>
          </div>
        </div>
        <div className="setup-footer">
          <button className="intro-button btn-positive ">
            {/* eslint-disable-next-line */}
            {beatLoader == true ? <BeatLoader size={8} /> : "Save"}
          </button>
        </div>
      </form>
    </>
  );
}

export default SetUp;
