import React, { useState } from "react";
import { Link } from "react-router-dom";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { Box } from "@chakra-ui/layout";
import ItemContainer from "./ItemContainer";
function Tabs({ items, setShow, dynamicBtn }) {
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  return (
    <>
      <nav>
        <div
          class="nav navigation-tabs nav-fill mt-4"
          id="nav-tab"
          role="tablist"
        >
          <button
            class="nav-link active"
            id="nav-home-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-home"
            type="button"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
          >
            <h3 style={{ fontFamily: "Avenir Regular" }}> Private</h3>
          </button>
          <button
            class="nav-link"
            id="nav-profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-profile"
            type="button"
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
          >
            <h3 style={{ fontFamily: "Avenir Regular" }}> Public</h3>
          </button>
          <button
            class="nav-link"
            id="nav-contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-contact"
            type="button"
            role="tab"
            aria-controls="nav-contact"
            aria-selected="false"
          >
            <h3 style={{ fontFamily: "Avenir Regular" }}> Watchlist</h3>
          </button>
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div
          class="tab-pane fade show active"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              style={
                dynamicBtn
                  ? {
                      border: "2px solid #B3995B",
                      background: "#B3995B",
                      color: "#fff",
                      fontWeight: "bold",
                      borderRadius: "30px",
                      margin: "25px",
                      width: "auto",
                      padding: "10px 20px",
                      fontFamily: "Avenir Regular",
                      fontSize: "15px",
                    }
                  : {
                      color: "#000",
                      border: "2px solid #B3995B",
                      borderRadius: "30px",
                      margin: "25px",
                      width: "auto",
                      padding: "10px 20px",
                      fontFamily: "Avenir Regular",
                      fontSize: "15px",
                    }
              }
              onClick={() => setShow(true)}
            >
              {dynamicBtn ? (
                <div>
                  <span>{dynamicBtn}</span>

                  <span>
                    <FilterAltIcon />
                  </span>
                </div>
              ) : (
                <>
                  <span style={{ color: "#B3995B" }}>View All</span>
                  <span>
                    <FilterAltIcon style={{ color: "#B3995B" }} />
                  </span>
                </>
              )}
            </button>
          </div>

          {/* PRIVATE */}

          {!loading && items.length < 1 ? (
            <div className="item-container">
              <div className="myPortfolioDiv">
                <div className="item-container-text">
                  <h5 style={{ fontFamily: "Avenir Medium" }}>
                    Create your Jewellery Portfolio now!
                  </h5>
                </div>
                <Box>
                  <p style={{ fontStyle: "italic", textAlign: "center" }}>
                    All information is kept secure & private until published to
                    Public showcase.​
                  </p>

                  <Link to="/portfolio/newentry">
                    <Fab className="item-container button" aria-label="add">
                      <AddIcon />
                    </Fab>
                  </Link>
                </Box>
              </div>
            </div>
          ) : (
            <>
              <ItemContainer items={items} />
              <Link to="/portfolio/newentry">
                <Fab className="item-container button active" aria-label="add">
                  <AddIcon />
                </Fab>
              </Link>
            </>
          )}
        </div>

        <div
          class="tab-pane fade"
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
        >
          <div style={{ display: "flex", justifyContent: "end" }}>
            <button
              style={
                dynamicBtn
                  ? {
                      border: "2px solid #B3995B",
                      background: "#B3995B",
                      color: "#fff",
                      fontWeight: "bold",
                      borderRadius: "30px",
                      margin: "25px",
                      width: "auto",
                      padding: "10px 20px",
                      fontFamily: "Avenir Regular",
                      fontSize: "15px",
                    }
                  : {
                      color: "#000",
                      border: "2px solid #B3995B",
                      borderRadius: "30px",
                      margin: "25px",
                      width: "auto",
                      padding: "10px 20px",
                      fontFamily: "Avenir Regular",
                      fontSize: "15px",
                    }
              }
              onClick={() => setShow(true)}
            >
              {dynamicBtn ? (
                <div>
                  <span>{dynamicBtn}</span>

                  <span>
                    <FilterAltIcon />
                  </span>
                </div>
              ) : (
                <>
                  {" "}
                  <span style={{ color: "#B3995B" }}>View All</span>
                  <span>
                    <FilterAltIcon style={{ color: "#B3995B" }} />
                  </span>
                </>
              )}
            </button>
          </div>

          <div className="item-container">
            {/* Public */}

            <div className="myPortfolioDiv">
              <div className="item-container-text">
                <h5 style={{ fontFamily: "Avenir Medium" }}>Public Showcase</h5>
              </div>
              <Box>
                <p style={{ fontStyle: "italic", textAlign: "center" }}>
                  Add the pieces you want to showcase to all Jewellery lovers on
                  AUCOVA.
                </p>

                <p
                  style={{
                    fontStyle: "italic",
                    marginTop: "20px",
                    opacity: "0.5",
                  }}
                >
                  COMING SOON!
                </p>
              </Box>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          id="nav-contact"
          role="tabpanel"
          aria-labelledby="nav-contact-tab"
        >
          <div style={{ display: "flex", justifyContent: "end" }}>
            <button
              style={
                dynamicBtn
                  ? {
                      border: "2px solid #B3995B",
                      background: "#B3995B",
                      color: "#fff",
                      fontWeight: "bold",
                      borderRadius: "30px",
                      margin: "25px",
                      width: "auto",
                      padding: "10px 20px",
                      fontFamily: "Avenir Regular",
                      fontSize: "15px",
                    }
                  : {
                      color: "#000",
                      border: "2px solid #B3995B",
                      borderRadius: "30px",
                      margin: "25px",
                      width: "auto",
                      padding: "10px 20px",
                      fontFamily: "Avenir Regular",
                      fontSize: "15px",
                    }
              }
              onClick={() => setShow(true)}
            >
              {dynamicBtn ? (
                <div>
                  <span>{dynamicBtn}</span>

                  <span>
                    <FilterAltIcon />
                  </span>
                </div>
              ) : (
                <>
                  {" "}
                  <span style={{ color: "#B3995B" }}>View All</span>
                  <span>
                    <FilterAltIcon style={{ color: "#B3995B" }} />
                  </span>
                </>
              )}
            </button>
          </div>

          <div className="item-container">
            {/* WATCHLIST */}

            <div className="myPortfolioDiv">
              <div className="item-container-text">
                <h5 style={{ fontFamily: "Avenir Medium" }}>Watchlist</h5>
              </div>
              <Box>
                <p
                  style={{
                    fontStyle: "italic",
                    textAlign: "center",
                    margin: "0px 65px",
                  }}
                >
                  Pieces that you are considering adding to your portfolio.
                </p>

                <p
                  style={{
                    fontStyle: "italic",
                    marginTop: "20px",
                    opacity: "0.5",
                  }}
                >
                  COMING SOON!
                </p>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tabs;
