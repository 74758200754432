import React from "react";

function Modal({ showUpload, setShowUpload, profileRef, removeProfilePic }) {
  return (
    <>
      <div
        className={`upload-modal ${showUpload ? "show" : "hide"}`}
        onClick={() => setShowUpload(false)}
      />
      <div className={`upload-modal-content ${showUpload ? "show" : "hide"}`}>
        <div className="upload-modal-body">
          <div
            className="upload-button"
            onClick={() => {
              profileRef.current.click();
              setShowUpload(false);
            }}
          >
            Upload from phone
          </div>

          <div
            className="upload-button"
            onClick={() => {
              removeProfilePic();
              setShowUpload(false);
            }}
          >
            <h5 style={{ color: "#A31111" }}>Remove profile picture</h5>
          </div>

          <div
            className="cancel-button"
            onClick={() => {
              setShowUpload(false);
            }}
          >
            Cancel
          </div>
        </div>
      </div>
    </>
  );
}

export default Modal;
