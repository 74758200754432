import React from "react";
import Slider from "react-slick";

const ItemSlider = ({ children }) => {
  const settings = {
    infinite: false,
    centerMode: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};

export default ItemSlider;
