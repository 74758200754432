import { Box } from "@mui/system";
import { deleteObject, ref } from "firebase/storage";
import React, { useContext, useState } from "react";
import { storage } from "../../firebase";
import FileUploader from "./FileUploader";
import UploadModal from "../../components/Modal/UploadModal/UploadModal";
import { useDispatch } from "react-redux";
import { selectImage, removeImage } from "../../redux/uploadSlice";
import { useSelector } from "react-redux";
import { useStorage } from "../../hooks/useStorage";
import { AuthContext } from "../../context/AuthContext";
import { useFileCompressor } from "../../hooks/useFileCompressor";

function NewEntry2({ category, setInput, input }) {
  const [showUpload, setShowUpload] = useState(false);
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line
  const { isStatus, setIsStatus } = useContext(AuthContext);

  const dispatch = useDispatch();

  const images = useSelector(selectImage);

  const { uploadToStorage } = useStorage();
  const { heicLoader, fileCompressor } = useFileCompressor();

  const openUpload = (e) => {
    e.preventDefault();
    setShowUpload(true);
  };

  const uploadFile = async (e) => {
    setLoading(true);
    setShowUpload(false);

    setIsStatus({
      ...isStatus,
      timeOutAllowed: false,
    });

    const file = e.target.files[0];
    console.log(`originalFile size ${file.size / 1024 / 1024} MB`);
    const compressedImage = await fileCompressor(file);

    await uploadToStorage(
      compressedImage,
      e.target.length ? e.target.name : "items"
    );
    console.log("uploaded image");
    setLoading(false);
  };

  const renderSwitch = () => {
    switch (category) {
      case "Rings":
        return <img src="/categories/Rings.svg" alt="img" />;
      case "Earrings":
        return <img src="/categories/Earrings.svg" alt="img" />;
      case "Pendants":
        return <img src="/categories/Pendants.svg" alt="img" />;
      case "Bracelets":
        return <img src="/categories/Bracelets.svg" alt="img" />;
      case "Brooches":
        return <img src="/categories/Brooches.svg" alt="img" />;
      case "Gems":
        return <img src="/categories/Gems.svg" alt="img" />;
      case "Others":
        return <img src="/categories/Others.svg" alt="img" />;
      default:
    }
  };
  const deletePicture = (removeIndex, fileName) => {
    const imageRef = ref(storage, `items/${fileName}`);
    deleteObject(imageRef);

    dispatch(removeImage(removeIndex));
  };

  return (
    <>
      <UploadModal
        showUpload={showUpload}
        setShowUpload={setShowUpload}
        setInput={setInput}
        input={input}
        uploadFile={uploadFile}
      />
      <Box display="flex" justifyContent="center" padding="15px 0">
        {renderSwitch()}
      </Box>

      <Box padding="5px 18px 10px">
        <h4 style={{ height: "27px" }}>Please upload photos of your piece</h4>
        <p>Suggested Views:</p>
        <p>Top, bottom, two sides and as worn</p>
      </Box>
      <div className="picture-header">
        <Box margin="0 20px" height="36px" display="flex" alignItems="center">
          <h4>Upload Pictures</h4>
        </Box>
      </div>
      <Box display="flex" flexWrap="wrap">
        {images.map((img, index) => {
          return (
            <div className="picture-container-item">
              <img
                style={{ width: "40vw", objectFit: "cover" }}
                src={img.fileUrl}
                alt="file"
              />

              <img
                src="/fixed/CloseButton.svg"
                className="picture-btn"
                onClick={() => deletePicture(index, img.fileName)}
                alt="img"
              />
            </div>
          );
        })}
        <FileUploader
          loading={loading}
          setLoading={setLoading}
          uploadFile={uploadFile}
          openUpload={openUpload}
          heicLoader={heicLoader}
        />
      </Box>
    </>
  );
}

export default NewEntry2;
