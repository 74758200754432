import Menu from "../../components/Menu";
import React, { useState } from "react";
import Header from "../../components/Header";
import MenuButton from "../../components/MenuButton";

function About() {
	const [visible, setVisible] = useState(false);
	const handleMouseDown = () => {
		setVisible(!visible);
	};
	return (
		<>
			<Header
				title="AUCOVA"
				leftButton={<MenuButton handleMouseDown={handleMouseDown} />}
			/>
			<Menu menuVisibility={visible} handleMouseDown={handleMouseDown} />
			<div className="about-container">
				<div className="about-header">
					<h2>About Aucova</h2>
				</div>
				<h5>
					Fine jewellery is the most emotive work of art any collector can ever
					own. Worn next to the skin & often marking significant life events,
					they are glittering with gemstones that are billions of years old and
					handed down from generation to generation.
				</h5>
				<br />
				<h5>
					AUCOVA helps fine Jewellery Collectors manage their collections by
					providing the secure technology, services & experts to assist in
					cataloging, maintaining, acquiring & trading their collections.
				</h5>
				<br />

				<div className="about-header">
					<h2>Experts & Partners</h2>
				</div>
				<h5>
					AUCOVA is fully supported by the Fine Jewellery Industry with
					connections with the most renowned experts, gemological labs, valuers,
					insurers, mine owners, designers and content creators from all over
					the world.
				</h5>
				<br />
				<h5>
					​ Analzying thousands of data points provided by the fine jewellery
					industry & supported by technology, AUCOVA delivers valuable insights
					to help fine jewellery collectors worldwide manage their fine
					jewellery collections.
				</h5>
				<div style={{ margin: "90px" }}></div>
				<h5>Terms & Conditions</h5>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa dui
					nisl, diam nunc rutrum tortor sagittis, pellentesque in. Id ligula
					diam non varius montes. Eu urna eu, nascetur laoreet. Vel habitant id
					risus amet. Commodo nisl faucibus morbi non lorem tristique lorem
					pretium. Mattis aliqueffff.sdfsdfsdf
				</p>
				<h5>Data & Privacy</h5>
				<p>
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa dui
					nisl, diam nunc rutrum tortor sagittis, pellentesque in. Id ligula
					diam non varius montes. Eu urna eu, nascetur laoreet. Vel habitant id
					risus amet. Commodo nisl faucibus morbi non lorem tristique lorem
					pretium. Mattis aliquet.
				</p>
			</div>
		</>
	);
}

export default About;
