import React, { useRef } from "react";
import "./UploadModal.css";

function UploadModal({ showUpload, setShowUpload, uploadFile }) {
  const cameraRef = useRef(null);
  const fileUploadRef = useRef(null);

  return (
    <>
      <div
        className={`upload-modal ${showUpload ? "show" : "hide"}`}
        onClick={() => setShowUpload(false)}
      />
      <div className={`upload-modal-content ${showUpload ? "show" : "hide"}`}>
        <div className="upload-modal-body">
          <div
            className="upload-button"
            onClick={() => {
              cameraRef.current.click();

              setShowUpload(false);
            }}
          >
            Take a photo
          </div>
          <input
            type="file"
            accept="image/*"
            capture="camera"
            onChange={uploadFile}
            hidden
            ref={cameraRef}
          />
          <div
            className="upload-button"
            onClick={() => fileUploadRef.current.click()}
          >
            Upload from phone
          </div>
          <input type="file" onChange={uploadFile} hidden ref={fileUploadRef} />

          <div
            className="cancel-button"
            onClick={() => {
              setShowUpload(false);
            }}
          >
            Cancel
          </div>
        </div>
      </div>
    </>
  );
}

export default UploadModal;
