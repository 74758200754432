import React, { useEffect } from "react";
import BaseModal from "./BaseModal";

const DeleteModal = ({ beatLoading, show, onClose, onDelete }) => {
  useEffect(() => {
    document.body.style = show ? "overflow: hidden !important" : "";
  }, [show]);
  return (
    <>
      {show && (
        <BaseModal
          handleTopButton={onDelete}
          handleBottomButton={onClose}
          topButton="Delete"
          bottomButton="Cancel"
          loading={beatLoading}
        >
          <div className="Modal-header">
            <div className="modal-title">Delete this entry?</div>
          </div>
          <div className="modal-body">
            <h5>Your information will be removed from Aucova’s database.</h5>
            <h5 style={{ padding: "0 0 2rem" }}>
              This action cannot be undone.
            </h5>
          </div>
        </BaseModal>
      )}
    </>
  );
};

export default DeleteModal;
