import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  updateDoc,
  getDoc,
  deleteDoc,
  doc,
  arrayRemove,
  onSnapshot,
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import ItemSlider from "../MyPortfolio/ItemSlider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LongMenuItem from "../../components/LongMenu/LongMenuItem";
import Header from "../../components/Header";
import LongMenuEntry from "../../components/LongMenu/LongMenuEntry";
import NewEntry3 from "../NewEntry/NewEntry3";
import CancelModal from "../../components/Modal/PopupModal/CancelModal";
import DeleteModal from "../../components/Modal/PopupModal/DeleteModal";
import ImageModal from "../../components/Modal/PopupModal/ImageModal";
import LoadingScreen from "../../components/LoadingScreen";
import MenuButton from "../../components/MenuButton";
import Swal from "sweetalert2";
import { BeatLoader } from "react-spinners";
import { deleteObject, ref } from "firebase/storage";
import { Box, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useDispatch, useSelector } from "react-redux";
import { resetInput, selectInput, updateInput } from "../../redux/uploadSlice";
import TermsModal from "../../components/Modal/PopupModal/TermsModal";
import { AuthContext } from "../../context/AuthContext";

function Item() {
  const [item, setItem] = useState(null);
  const [showEdit, setShowEdit] = useState(0);
  const [show, setShow] = useState(false);
  const [showImg, setShowImg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [beatLoading, setbeatLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [username, setUsername] = useState();
  const [profilePic, setProfilePic] = useState();
  const [showMenu, setShowMenu] = useState(false);
  const [img, setImg] = useState();
  const [beatLoader, setBeatLoader] = useState(false);
  const [beatLoader1, setBeatLoader1] = useState(false);
  const id = useParams().id;
  const [openModal, setOpenModal] = useState(false);

  // eslint-disable-next-line
  const { isStatus, setIsStatus } = useContext(AuthContext);

  // const closeModal = () => {
  //   setOpenModal(false);
  // };

  // const modalInformation = [
  //   {
  //     title: "Terms & Conditions",
  //     body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa dui nisl, diam nunc rutrum tortor sagittis, pellentesque in. Id ligula diam non varius montes. Eu urna eu, nascetur laoreet. Vel habitant id risus amet. Commodo nisl faucibus morbi non lorem tristique lorem pretium. Mattis aliquet.",
  //     button: "Cancel",
  //   },
  // ];

  const handleMouseDown = () => {
    setVisible(!visible);
  };

  const dispatch = useDispatch();
  const input = useSelector(selectInput);
  console.log(input);
  const fetchItems = async () => {
    setLoading(true);
    onSnapshot(doc(db, "users", user.uid), (res) => {
      setUsername(res.data().username);

      if (res.data().profilePic) {
        setProfilePic(res.data().profilePic.url);
      }
    });
    await getDoc(doc(db, "items", id)).then((res) => {
      setItem(res.data());

      dispatch(updateInput(res.data()));
      setLoading(false);
    });
  };

  useEffect(() => {
    // eslint-disable-next-line
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = [
    "Edit",
    "Delete",
    "Publish To:",
    "Private Only",
    "Public View",
    "Marketplace",
  ];

  const onDiscard = (e) => {
    e.preventDefault();

    if (isStatus.code === "BIOMETRIC_DISABLED") {
      setIsStatus({
        ...isStatus,
      });
    } else {
      setIsStatus({
        ...isStatus,
        timeOutAllowed: true,
      });
    }

    dispatch(resetInput());
    navigate("/portfolio");
  };

  const editEntry = () => {
    if (isStatus.code === "BIOMETRIC_DISABLED") {
      setIsStatus({
        ...isStatus,
      });
    } else {
      setIsStatus({
        ...isStatus,
        timeOutAllowed: false,
      });
    }

    setShowEdit(1);
  };

  const onClose = () => {
    setShowImg(false);
  };
  // console.log(item);
  const user = JSON.parse(localStorage.getItem("user"));
  let navigate = useNavigate();

  // remove from user.items array and delete from storage
  // if img, receipt, certificate exists

  const deleteEntry = async () => {
    setbeatLoading(true);
    await updateDoc(doc(db, "users", user.uid), {
      items: arrayRemove(id),
    })
      .then(() => {
        deleteDoc(doc(db, "items", id));
        if (item.certificate.img.length > 0) {
          item.certificate.img.map((cert) =>
            deleteObject(ref(storage, `certificate/${cert.fileName}`))
          );
        }
        if (item.receipts.length > 0) {
          item.receipts.map((receipt) =>
            deleteObject(ref(storage, `receipts/${receipt.fileName}`))
          );
        }
        if (item.img.length > 0) {
          item.img.map((img) =>
            deleteObject(ref(storage, `items/${img.fileName}`))
          );
        }
      })
      .then(() => {
        setbeatLoading(false);
        Swal.fire({
          icon: "success",
          title: "Your entry has been deleted!",

          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/portfolio");
      })
      .catch((err) => console.log(err.message));
    dispatch(resetInput());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setbeatLoading(true);

    if (input.title == null || input.title == "" || input.title.trim() === "") {
      setbeatLoading(false);
      Swal.fire({
        icon: "error",
        title: "Please fill in the title",
        confirmButtonColor: "#B3995B",
      });
    } else if (input.img.length === 0) {
      setbeatLoading(false);
      Swal.fire({
        icon: "error",
        title: "Please upload at least one photo.",
        confirmButtonColor: "#B3995B",
      });
    } else {
      await updateDoc(doc(db, "items", id), {
        ...input,
      })
        .then(() => {
          setbeatLoading(false);

          if (isStatus.code === "BIOMETRIC_DISABLED") {
            setIsStatus({
              ...isStatus,
            });
          } else {
            setIsStatus({
              ...isStatus,
              timeOutAllowed: true,
            });
          }

          Swal.fire({
            icon: "success",
            title: "Your entry has been saved!",

            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/portfolio");
        })
        .catch((err) => console.log(err.message));
    }
    dispatch(resetInput());
  };

  return (
    <div>
      {/* eslint eqeqeq: 0 */}
      {loading == true ? (
        <div>
          <Header
            title="My Portfolio"
            leftButton={<MenuButton handleMouseDown={handleMouseDown} />}
          />

          <LoadingScreen />
        </div>
      ) : (
        <>
          {showEdit === 0 && Boolean(item) && (
            <>
              <Header
                title={item.category}
                leftButton={
                  <img
                    src="/fixed/Back.svg"
                    alt="img"
                    onClick={() => {
                      dispatch(resetInput());

                      if (isStatus.code === "BIOMETRIC_DISABLED") {
                        setIsStatus({
                          ...isStatus,
                        });
                      } else {
                        setIsStatus({
                          ...isStatus,
                          timeOutAllowed: true,
                        });
                      }
                      navigate(-1);
                    }}
                  />
                }
                rightButton={
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={showMenu ? "long-menu" : undefined}
                    aria-expanded={showMenu ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={() => setShowMenu(true)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                }
              />
              <LongMenuItem
                showMenu={showMenu}
                deleteEntry={() => setShow(true)}
                editEntry={editEntry}
                options={options}
                setShowMenu={setShowMenu}
              />
              <DeleteModal
                show={show}
                onClose={() => setShow(false)}
                onDelete={deleteEntry}
                beatLoading={beatLoading}
              />

              <Box width="100%" display="flex" justifyContent="center">
                <div className="profile-small">
                  {profilePic && (
                    <img
                      src={profilePic}
                      alt=""
                      className="profile-pic-small"
                    />
                  )}
                  {!profilePic && (
                    <img src="/fixed/DefaultProfile.svg" alt="" />
                  )}
                </div>
                <Box display="flex" alignItems="center">
                  <h3>{username}</h3>
                </Box>
              </Box>
              <ItemSlider>
                {item?.img.map((img) => {
                  return (
                    <div className="itemslider-container">
                      <img
                        src={img.fileUrl}
                        width="100%"
                        style={{
                          maxHeight: "27vh",
                          maxWidth: "70vw",
                          objectFit: "contain",
                        }}
                        alt="img"
                      />
                    </div>
                  );
                })}
              </ItemSlider>
              <div className="detail-title">{item.title}</div>
              <div className="form">
                <div className="form-container">
                  <label>Diamonds / Gemstones</label>
                  <div style={{ marginTop: "17px" }}>
                    {item.type.length > 0 ? (
                      item.type.map((type) => (
                        <span className="chip">{type}</span>
                      ))
                    ) : (
                      <div style={{ marginTop: "1.5px" }}>None</div>
                    )}
                  </div>
                </div>
                <div className="form-container">
                  <label>Description</label>
                  <div style={{ marginTop: "1.5px" }}>
                    {item.description || "None"}
                  </div>
                </div>
                <div className="form-container">
                  <label>Location</label>
                  <div style={{ marginTop: "1.5px" }}>
                    {item.location || "None"}
                  </div>
                </div>
                <div className="form-container">
                  <label>Purchase Price</label>
                  <p>Historical Value</p>
                  <div style={{ marginTop: "1.5px" }}>
                    {item.purchase_price.value
                      ? item.purchase_price.currency + item.purchase_price.value
                      : "None"}
                  </div>
                </div>
                <div className="form-container">
                  <label>Assessed Value</label>

                  <div
                    style={{
                      marginTop: "1.5px",
                    }}
                  >
                    SGD $ 0
                    {item.assessed && (
                      <span
                        style={{
                          marginLeft: "17px",
                        }}
                      >
                        <img src="/fixed/Assessed.svg" alt="" />
                      </span>
                    )}
                  </div>
                  {!item.assessed && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        className="upload-btn"
                        onClick={() => {
                          setBeatLoader(true);
                          setTimeout(() => {
                            navigate("/contactform");
                          }, 100);
                        }}
                      >
                        {beatLoader === true ? (
                          <BeatLoader size={8} />
                        ) : (
                          "Request for Services"
                        )}
                      </button>
                    </div>
                  )}
                </div>
                <ImageModal
                  show={showImg}
                  onClose={onClose}
                  img={img}
                ></ImageModal>
                <div className="form-container">
                  <label>Receipts & Documents</label>
                  {item.receipts.length > 0 ? (
                    item.receipts.map((receipt, index) => (
                      <div key={{ index }} style={{ margin: "10px" }}>
                        <img src="/fixed/Document.svg" alt="img" />
                        <span
                          style={{
                            marginLeft: "10px",
                            textDecoration: "underline",
                          }}
                          onClick={() => {
                            setShowImg(true);
                            setImg(receipt.fileUrl);
                          }}
                        >
                          {receipt.fileName}
                        </span>
                      </div>
                    ))
                  ) : (
                    <div style={{ marginTop: "1.5px" }}>None</div>
                  )}
                </div>
                <div className="form-container" style={{ marginTop: "50px" }}>
                  <label>Certificate Type</label>
                  <div style={{ marginTop: "1.5px" }}>
                    {item.certificate.type || "None"}
                  </div>
                  <div style={{ margin: "20px 0 0" }}>
                    <label>Certificate</label>
                    {item.certificate.img.length > 0 ? (
                      item.certificate.img.map((cert, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              margin: "10px",
                              textDecoration: "underline",
                            }}
                          >
                            <img src="/fixed/Document.svg" alt="img" />
                            <span
                              style={{ marginLeft: "10px" }}
                              onClick={() => {
                                setShowImg(true);
                                setImg(cert.fileUrl);
                              }}
                            >
                              {cert.fileName}
                            </span>
                          </div>
                        );
                      })
                    ) : (
                      <div style={{ marginTop: "1.5px" }}>None</div>
                    )}
                  </div>
                </div>
                <div className="form-title">Insurance Details</div>
                <div className="form-container">
                  {item.insurance.name && item.insurance.value ? (
                    <>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <label>Insurer</label>
                        <div>{item.insurance.name}</div>
                        <div style={{ marginTop: "20px" }}>
                          <label>Insured Value</label>
                          <div>
                            {item.insurance.currency} {item.insurance.value}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <label>Insurance</label>
                      <div style={{ marginTop: "1.5px" }}>None</div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <button
                          className="upload-btn"
                          onClick={() => {
                            setBeatLoader1(true);
                            setTimeout(() => {
                              navigate("/contactform");
                            }, 100);
                          }}
                        >
                          {beatLoader1 === true ? (
                            <BeatLoader size={8} />
                          ) : (
                            "Request for Services"
                          )}
                        </button>
                      </div>
                    </>
                  )}
                </div>
                <div className="form-title">Inheritance Planning</div>
                <div className="form-container">
                  <label>Inheritance Planning</label>
                  {item.inheritance.name && item.inheritance.comments ? (
                    <>
                      <label>For</label>
                      <div> {item.inheritance.name}</div>
                      <div style={{ margin: "20px 0px" }}>
                        <label>Notes</label>
                        <div>{item.inheritance.comments}</div>
                      </div>
                    </>
                  ) : (
                    <div style={{ marginTop: "1.5px" }}>None</div>
                  )}
                </div>

                <div>
                  <div className="tc" onClick={() => setOpenModal(true)}>
                    Terms and conditions apply
                  </div>
                  <TermsModal
                    show={openModal}
                    onClose={() => setOpenModal(false)}
                  />
                </div>
              </div>
            </>
          )}

          {showEdit === 1 && (
            <>
              <CancelModal
                show={show}
                onClose={() => setShow(false)}
                onSave={handleSubmit}
                onDiscard={onDiscard}
              />
              <Header
                title="Add Details"
                leftButton={
                  <img
                    src="/fixed/Vector.png"
                    alt="img"
                    onClick={(e) => {
                      e.preventDefault();
                      setShow(true);
                    }}
                  />
                }
                rightButton={
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={showMenu ? "long-menu" : undefined}
                    aria-expanded={showMenu ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={() => setShowMenu(true)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                }
              />

              <LongMenuEntry
                handleSubmit={handleSubmit}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
              />
              <NewEntry3 />
              <button className="saveBtn" onClick={handleSubmit} type="submit">
                {beatLoading == true ? (
                  <BeatLoader size={8} />
                ) : (
                  <h5 style={{ fontFamily: "Avenir Bold" }}>Save</h5>
                )}
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Item;
