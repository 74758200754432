import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Homepage from "./pages/HomePage/Homepage";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { useEffect, useState, useRef } from "react";
import "./styles.css";
import Dispatch from "./pages/Dispatch/Dispatch";
import MyPortfolio from "./pages/MyPortfolio/MyPortfolio";
import Item from "./pages/Item/Item";
import Intro from "./pages/Intro/Intro";
import NewEntry from "./pages/NewEntry/NewEntry";
import LoadingScreen from "./components/LoadingScreen";
import { FormStateContext } from "./context/FormStateContext";
import { AuthContext } from "./context/AuthContext";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import SetUp from "./pages/SetUp/SetUp";
import Settings from "./pages/Settings/Settings";
import About from "./pages/About/About";
import { useLocalStorage } from "./hooks/useLocalStorage";
import ContactForm from "./pages/ContactForm/ContactForm";
import { useDispatch } from "react-redux";
import { updateUser } from "./redux/authSlice";
import Reauth from "./pages/Intro/Reauth";

function App() {
  const [state, setState] = useState(1);
  const [userCred, setUserCred] = useLocalStorage("user", null);
  const [isStatus, setIsStatus] = useLocalStorage("status", null);

  const dispatch = useDispatch();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // console.log(user);
        setUserCred({
          username: user.displayName,
          email: user.email,
          // verificationStatus: user.emailVerified,
          uid: user.uid,
        });

        setIsStatus({
          ...isStatus,
        });
        // console.log(user);
        dispatch(updateUser(JSON.parse(localStorage.getItem("user")).uid));
      } else {
        console.log("else");
        setUserCred(null);
        dispatch(updateUser(null));
      }
    });

    // console.log(process.env);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logoutTimerIdRef = useRef(null);

  const logOutUser = () => {
    setIsStatus({ code: "BIOMETRIC_TIME_OUT", timeOutAllowed: true });
  };

  useEffect(() => {

    // 1 second timeout
    const autoLogout = () => {
      if (
        isStatus.code === "BIOMETRIC_AUTHENTICATED" &&
        isStatus.timeOutAllowed === true
      ) {
        if (document.visibilityState === "hidden") {
          const timeOutId = window.setTimeout(logOutUser, 1000);
          logoutTimerIdRef.current = timeOutId;
        } else {
          window.clearTimeout(logoutTimerIdRef.current);
        }
      }
    };

    document.addEventListener("visibilitychange", autoLogout);

    return () => {
      document.removeEventListener("visibilitychange", autoLogout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStatus]);

  useEffect(() => {

    // 5 mins timeout when uploading new assets
    const autoLogout = () => {
      if (
        isStatus.code === "BIOMETRIC_AUTHENTICATED" &&
        isStatus.timeOutAllowed === false
      ) {
        if (document.visibilityState === "hidden") {
          const timeOutId = window.setTimeout(logOutUser, 5 * 60 * 1000); // 5 minutes
          logoutTimerIdRef.current = timeOutId;
        } else {
          window.clearTimeout(logoutTimerIdRef.current);
        }
      }
    };

    document.addEventListener("visibilitychange", autoLogout);

    return () => {
      document.removeEventListener("visibilitychange", autoLogout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStatus]);

  return (
    <>
      <AuthContext.Provider
        value={{
          userCred,
          setUserCred,
          isStatus,
          setIsStatus,
        }}
      >
        <FormStateContext.Provider value={{ setState, state }}>
          <BrowserRouter>
            <Routes>
              {userCred ? (
                <>
                  {userCred.username ? (
                    <>
                      {isStatus.code === "BIOMETRIC_TIME_OUT" ||
                      isStatus.code === "BIOMETRIC_NOT_AUTHENTICATED" ? (
                        <>
                          <Route path="/loading" element={<LoadingScreen />} />
                          <Route path="/reauth" element={<Reauth />} />
                          <Route path="*" element={<Navigate to="/reauth" />} />
                        </>
                      ) : (
                        <>
                          <Route path="/" element={<Homepage />} />
                          <Route path="/about" element={<About />} />
                          <Route path="/dispatch" element={<Dispatch />} />
                          <Route path="/portfolio" element={<MyPortfolio />} />
                          <Route
                            path="/portfolio/newentry"
                            element={<NewEntry />}
                          />
                          <Route path="/item/:id" element={<Item />} />
                          <Route path="/settings" element={<Settings />} />
                          <Route
                            path="/contactform"
                            element={<ContactForm />}
                          />
                          <Route path="/loading" element={<LoadingScreen />} />
                          <Route path="*" element={<Navigate to="/" />} />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Route path="/loading" element={<LoadingScreen />} />
                      <Route path="/setup" element={<SetUp />} />
                      <Route path="*" element={<Navigate to="/setup" />} />
                    </>
                  )}
                </>
              ) : (
                <>
                  <Route path="/loading" element={<LoadingScreen />} />
                  <Route path="/intro" element={<Intro />} />
                  <Route path="*" element={<Navigate to="/intro" />} />
                </>
              )}
            </Routes>
          </BrowserRouter>
        </FormStateContext.Provider>
      </AuthContext.Provider>
    </>
  );
}

export default App;
