/* sublist of items taken from item capture*/

export const options_type = [
  { value: "AMBER", label: "AMBER" },
  { value: "AMETHYST", label: "AMETHYST" },
  { value: "AMETRINE", label: "AMETRINE" },
  { value: "APATITE", label: "APATITE" },
  { value: "ALEXANDRITE", label: "ALEXANDRITE" },
  { value: "AQUAMARINE", label: "AQUAMARINE" },
  { value: "HELIODOR", label: "HELIODOR" },
  { value: "BERYL", label: "BERYL" },
  { value: "CHRYSOBERYL ", label: "CHRYSOBERYL " },
  { value: "CHROME DIOPSIDE ", label: "CHROME DIOPSIDE " },
  { value: "CHROME TOURMALINE ", label: "CHROME TOURMALINE " },

  { value: "CITRINE", label: "CITRINE" },
  { value: "DIAMOND", label: "DIAMOND" },
  { value: "EMERALD", label: "EMERALD" },
  { value: "GARNET - ALMANDINE", label: "GARNET - ALMANDINE" },
  { value: "GARNET - DEMANTOID", label: "GARNET - DEMANTOID" },
  { value: "GARNET- GROSSULAR", label: "GARNET- GROSSULAR" },
  { value: "GARNET – PYROPE", label: "GARNET – PYROPE" },
  { value: "GARNET – RHODOLITE", label: "GARNET – RHODOLITE" },
  { value: "GARNET - SPESSARTINE", label: "GARNET - SPESSARTINE" },
  {
    value: "GARNET - SPESSARTINE-MANDARIN",
    label: "GARNET - SPESSARTINE-MANDARIN",
  },
  { value: "GARNET – TSAVORITE", label: "GARNET – TSAVORITE" },
  { value: "IMPERIAL TOPAZ", label: "IMPERIAL TOPAZ" },
  { value: "IOLITE", label: "IOLITE" },
  { value: "INDICOLITE", label: "INDICOLITE" },
  { value: "KUNZITE", label: "KUNZITE" },
  { value: "MADEIRA QUARTZ", label: "MADEIRA QUARTZ" },
  { value: "MOONSTONE", label: "MOONSTONE" },
  { value: "MORGANITE", label: "MORGANITE" },
  { value: "NEPHRITE", label: "NEPHRITE" },
  { value: "OPAL", label: "OPAL" },
  { value: "PARAIBA TOURMALINE", label: "PARAIBA TOURMALINE" },
  { value: "PERIDOT", label: "PERIDOT" },
  { value: "PEARL", label: "PEARL" },
  { value: "ROSE QUARTZ", label: "ROSE QUARTZ" },
  { value: "RUBELLITE", label: "RUBELLITE" },
  { value: "RUBY", label: "RUBY" },
  { value: "SAPPHIRE", label: "SAPPHIRE" },
  { value: "SPINEL", label: "SPINEL" },
  { value: "TOPAZ", label: "TOPAZ" },
  { value: "TOURMALINE", label: "TOURMALINE" },
  { value: "TURQUOISE", label: "TURQUOISE" },
  { value: "ZIRCON", label: "ZIRCON" },
  { value: "OTHERS", label: "OTHERS" },
];

export const options_currency = [
  { value: "SGD", label: "SGD $" },
  { value: "USD", label: "USD $" },
  { value: "RMB", label: "RMB ¥" },
];

export const options_certificate = [
  { value: "AGL (USA)", label: "AGL (USA)" },
  { value: "AIG (International)", label: "AIG (International)" },
  { value: "AIGS (Thailand)", label: "AIGS (Thailand)" },
  { value: "CGL (Japan)", label: "CGL (Japan)" },
  { value: "CISGEM (Italy)", label: "CISGEM (Italy)" },
  { value: "DSEF (Germany)", label: "DSEF (Germany)" },
  { value: "GGTL (Sri Lanka)", label: "GGTL (Sri Lanka)" },
  { value: "GIC (Sri Lanka)", label: "GIC (Sri Lanka)" },
  { value: "GIT-Gem (Thailand)", label: "GIT-Gem (Thailand)" },
  { value: "FGA (UK)", label: "FGA (UK)" },
  { value: "GIA (International)", label: "GIA (International)" },
  { value: "Gübelin (Switzerland)", label: "Gübelin (Switzerland)" },
  { value: "GRS (International)", label: "GRS (International)" },
  { value: "IGI (International)", label: "IGI (International)" },
  { value: "Lotus Gemology (Thailan)", label: "Lotus Gemology (Thailan)" },
  {
    value: "National Gemstone Testing Center (China)",
    label: "National Gemstone Testing Center (China)",
  },
  {
    value: "Nan Yang Gemological Institute (Singapore)",
    label: "Nan Yang Gemological Institute (Singapore)",
  },
  { value: "SSEF (Switzerland)", label: "SSEF (Switzerland)" },
  { value: "Others", label: "Others" },
];
export const typeStyle = {
  option: (provided, state) => ({
    ...provided,

    color: "black",
    backgroundColor: "white",
  }),

  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),

  placeholder: (provided) => ({
    ...provided,
    fontSize: "16px",
    letterSpacing: "0.46px",
    fontFamily: "Avenir Regular",
    color: "#cccccc",
  }),
  control: (provided, state) => ({
    ...provided,
    border: "0",
    borderBottom: "1px solid  rgba(0, 0, 0, 0.42);",
    borderColor: state.isFocused ? "0" : "0",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.42)",
    },
    borderRadius: "0",
    boxShadow: "none",

    minHeight: "0",
  }),

  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#b3993b",
    // transition: "all .2s ease",
    // transform: state.isFocused ? "rotate(180deg)" : null,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0",
    padding: "1.5px 0",
  }),
};
export const currencyStyle = {
  option: (provided, state) => ({
    ...provided,

    color: state.isSelected ? "black" : "black",
    backgroundColor: state.isSelected ? "white" : "white",
  }),

  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
    alignItems: "flex-end",
  }),

  container: () => ({
    width: "35vw",
    margin: 0,
  }),

  placeholder: (provided) => ({
    ...provided,
    fontSize: "16px",
    letterSpacing: "0.46px",
    fontFamily: "Avenir Regular",
    color: "#cccccc",
  }),
  control: (provided, state) => ({
    ...provided,
    border: "0",
    borderBottom: "1px solid  rgba(0, 0, 0, 0.42)",
    borderColor: state.isFocused ? "0" : "0",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.42)",
    },
    borderRadius: "0",
    boxShadow: "none",
    padding: "3.5px 0",
    minHeight: "0",
  }),

  input: (provided) => ({
    ...provided,
    margin: " 0",
    padding: "0",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#b3993b",
    padding: "0 0 3px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    alignItems: "end",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

export const certificateStyle = {
  option: (provided, state) => ({
    ...provided,

    color: state.isSelected ? "black" : "black",
    backgroundColor: state.isSelected ? "white" : "white",
  }),

  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),

  placeholder: (provided) => ({
    ...provided,
    fontSize: "16px",
    letterSpacing: "0.46px",
    fontFamily: "Avenir Regular",
    color: "#cccccc",
  }),
  control: (provided, state) => ({
    ...provided,
    border: "0",
    borderBottom: "1px solid  rgba(0, 0, 0, 0.42);",
    borderColor: state.isFocused ? "0" : "0",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.42)",
    },
    borderRadius: "0",
    boxShadow: "none",
    minHeight: "0",
  }),

  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#b3993b",
    padding: "0",
    // transition: "all .2s ease",
    // transform: state.isFocused ? "rotate(180deg)" : null,
  }),

  indicatorSeparator: () => ({
    display: "none",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0",
    padding: "1.5px 0",
  }),
};
