import imageCompression from "browser-image-compression";
import heic2any from "heic2any";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";

export const useFileCompressor = () => {
  const [heicLoader, setHeicLoader] = useState(false);

  const fileCompressor = async (file) => {
    const getExtension = file.name.split(".").pop().toLowerCase();
    console.log(getExtension);

    // eslint-disable-next-line
    if (getExtension == "heic") {
      try {
        setHeicLoader(true);
        // Convert from heic to jpg
        console.log("heic file detected");
        const resultBlob = await heic2any({
          blob: file,
          toType: "image/jpg",
        });
        console.log(resultBlob);

        // eslint-disable-next-line no-useless-concat
        const res = new File([resultBlob], `${uuidv4()}` + ".jpg", {
          type: "image/jpg",
          lastModified: new Date().getTime(),
        });
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
        };
        const compressedImage = await imageCompression(res, options);
        console.log(
          `Compressed Image size ${compressedImage.size / 1024 / 1024} MB`
        );
        setHeicLoader(false);
        return compressedImage;
      } catch (err) {
        alert(err);
      }
    } else {
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
        };
        const compressedImage = await imageCompression(file, options);
        console.log(
          `Compressed Image size ${compressedImage.size / 1024 / 1024} MB`
        );
        return compressedImage;
      } catch (error) {
        alert(error);
      }
    }
  };

  return { fileCompressor, heicLoader };
};
