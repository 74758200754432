import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Tabs from "./Tabs";
import { Link } from "react-router-dom";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase";
// import Fab from "@mui/material/Fab";
// import AddIcon from "@mui/icons-material/Add";
import Menu from "../../components/Menu";
import MenuButton from "../../components/MenuButton";
import CategoryModal from "../NewEntry/CategoryModal/CategoryModal";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  ChakraProvider,
} from "@chakra-ui/react";
import { Box, Divider } from "@chakra-ui/layout";
import LoadingScreen from "../../components/LoadingScreen";
import { useLocation } from "react-router-dom";

function MyPortfolio() {
  const [items, setItems] = useState([]);
  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState();
  const [profilePic, setProfilePic] = useState();
  const [username, setUsername] = useState();
  const [navBar, setNavBar] = useState(false);
  const location = useLocation();
  const [btnValue, setBtnValue] = useState();

  const user = JSON.parse(localStorage.getItem("user"));

  const getTotalValue = () => {
    const subtotal = items.map((item) => {
      return parseFloat(item.purchase_price.value) || 0;
    });

    const totalValue = subtotal.reduce((total, item) => total + item, 0);

    return new Intl.NumberFormat("en-SG").format(totalValue);
  };

  const fetchItems = async () => {
    setLoading(true);
    onSnapshot(doc(db, "users", user.uid), (res) => {
      setUsername(res.data().username);

      if (res.data().profilePic) {
        setProfilePic(res.data().profilePic.url);
      }

      if (res.data().items.length > 0) {
        res.data().items.forEach(async (item) => {
          const docSnap = await getDoc(doc(db, "items", item));

          setItems((prev) => [...prev, docSnap.data()]);
          setFilteredItems((prev) => [...prev, docSnap.data()]);

          setLoading(false);
        });
      } else {
        setItems([]);
        setFilteredItems([]);
        setLoading(false);
      }
    });
  };

  const setFilter = (cat) => {
    setBtnValue(cat);
    switch (cat) {
      case "Rings":
        setFilteredItems(items.filter((item) => item.category === "Rings"));
        break;
      case "Earrings":
        setFilteredItems(items.filter((item) => item.category === "Earrings"));
        break;
      case "Pendants / Necklaces":
        setFilteredItems(
          items.filter((item) => item.category === "Pendants / Necklaces")
        );
        break;
      case "Bracelets / Bangles":
        setFilteredItems(
          items.filter((item) => item.category === "Bracelets / Bangles")
        );
        break;
      case "Brooches":
        setFilteredItems(items.filter((item) => item.category === "Brooches"));
        break;
      case "Gems":
        setFilteredItems(items.filter((item) => item.category === "Gems"));
        break;
      case "Others":
        setFilteredItems(items.filter((item) => item.category === "Others"));
        break;
      default:
        setFilteredItems(items);
        break;
    }
  };
  const handleMouseDown = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    setValue(getTotalValue());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (location.pathname == "/portfolio") {
      setNavBar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const categories = [
    "View all",
    "Rings",
    "Earrings",
    "Pendants / Necklaces",
    "Bracelets / Bangles",
    "Brooches",
    "Gems",
    "Others",
  ];
  return (
    <ChakraProvider>
      <div>
        {navBar == true ? (
          <>
            <Header
              title="My Portfolio"
              leftButton={<MenuButton handleMouseDown={handleMouseDown} />}
            />
            <Menu menuVisibility={visible} handleMouseDown={handleMouseDown} />{" "}
          </>
        ) : (
          ""
        )}

        <CategoryModal
          show={show}
          onClose={() => setShow(false)}
          header="View by Categories"
          categories={categories}
          setCategory={setFilter}
        />
        {/* eslint eqeqeq: 0 */}
        {loading == true ? (
          <LoadingScreen />
        ) : (
          <>
            <div className="flex-column p-4">
              <div className="d-flex justify-content-center">
                <div className="setup-profile">
                  {profilePic && (
                    <img src={profilePic} alt="" className="profile-pic" />
                  )}
                  {!profilePic && (
                    <img src="/fixed/DefaultProfile.svg" alt="default pic" />
                  )}
                </div>
              </div>

              <h2 className=" d-flex justify-content-center mt-2">
                {username}
              </h2>
            </div>

            <Accordion
              allowToggle
              defaultIndex={0}
              style={
                isExpanded
                  ? { borderRadius: "0 " }
                  : { borderRadius: "0 0 40px 40px" }
              }
            >
              <AccordionItem>
                <AccordionButton onClick={() => setIsExpanded(!isExpanded)}>
                  <Box flex="1" textAlign="left" marginRight="10px">
                    {isExpanded ? (
                      <Box display="flex" justifyContent="space-between">
                        <h5 style={{ fontFamily: "Avenir Bold" }}>
                          Total Value
                        </h5>
                        <h5>S$</h5>
                        <h5>0</h5>
                      </Box>
                    ) : (
                      <Box display="flex" justifyContent="space-between">
                        <h5 style={{ fontFamily: "Avenir Bold" }}>
                          Total Value Details
                        </h5>
                      </Box>
                    )}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel pb={4}>
                  {items.length > 0 ? (
                    <>
                      <Box display="flex" justifyContent="space-between">
                        <h5>Total No. of Pieces</h5>
                        <h5>{items.length}</h5>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        pt="22px"
                      >
                        <h5>Total Purchase Price </h5>
                        <h5>S$</h5>
                        <h5>{value}</h5>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        p="22px 0"
                      >
                        <h5>Assessed </h5>

                        <h5>0</h5>
                      </Box>
                      <Divider />
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        p="10px 0"
                      >
                        <h5>Total Assessed Value</h5>
                        <h5>S$</h5>
                        <h5>0</h5>
                      </Box>
                      <Divider />
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        p="22px 0"
                      >
                        <h5 style={{ fontFamily: "Avenir Bold" }}>
                          Assessed Value Unknown
                        </h5>
                        <h5 style={{ fontFamily: "Avenir Bold" }}>0</h5>
                      </Box>
                      <Box marginBottom="40px">
                        <Link
                          className="d-flex justify-content-center"
                          to="/contactform"
                        >
                          <h5
                            style={{
                              fontFamily: "Avenir Bold",
                              textDecoration: "underline",
                              color: "#b3995b",
                            }}
                          >
                            Request for assessment
                          </h5>
                        </Link>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box display="flex" justifyContent="space-between">
                        <h5>Total No. of Pieces</h5>
                        <h5>{items.length}</h5>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        p="22px 0"
                      >
                        <h5>Assessed</h5>
                        <h5>0</h5>
                      </Box>
                      <Divider />
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        p="10px 0 26px"
                      >
                        <h5>Total Assessed Value</h5>
                        <h5>S$</h5>
                        <h5>0</h5>
                      </Box>
                    </>
                  )}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <Tabs
              items={filteredItems}
              setShow={setShow}
              dynamicBtn={btnValue}
            />
          </>
        )}
      </div>
    </ChakraProvider>
  );
}

export default MyPortfolio;
