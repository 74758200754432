import React, { useState, useContext } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { BeatLoader } from "react-spinners";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

const SignUp = ({ setState }) => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [beatLoader, setBeatLoader] = useState(false);

	// eslint-disable-next-line
	const { isStatus, setIsStatus } = useContext(AuthContext);

	// eslint-disable-next-line
	const navigate = useNavigate();

	const register = () => {
		setBeatLoader(true);

		if (email === "" || password === "" || confirmPassword === "") {
			setBeatLoader(false);
			Swal.fire({
				icon: "error",
				title: "Please fill in the empty fields.",
				confirmButtonColor: "#B3995B",
			});
		} else if (password === confirmPassword) {
			// console.log("Password matches!");

			createUserWithEmailAndPassword(auth, email, password)
				.then(() => {
					setBeatLoader(false);

					setIsStatus({
						code: "BIOMETRIC_NOT_AUTHENTICATED",
						timeOutAllowed: true,
					});

					navigate("/setup");
				})
				.catch((err) => {
					console.log(err.message);
					setBeatLoader(false);
					if (err.message == "Firebase: Error (auth/invalid-email).") {
						Swal.fire({
							icon: "error",
							title: "You've entered an invalid email.",
							confirmButtonColor: "#B3995B",
						});
					} else if (
						err.message == "Firebase: Error (auth/email-already-in-use)."
					) {
						Swal.fire({
							icon: "error",
							title: "The existing email is in use.",
							confirmButtonColor: "#B3995B",
						});
					} else {
						Swal.fire({
							icon: "error",
							title: err.message,
							confirmButtonColor: "#B3995B",
						});
					}
				});

			// createUserWithEmailAndPassword(auth, email, password)
			//   .then(() => {
			//     setBeatLoader(false);

			//     sendEmailVerification(auth.currentUser)
			//       .then(() => {
			//         Swal.fire({
			//           icon: "info",
			//           title: "Verify your email address",
			//           text: "Please click on the link sent to your email",
			//           confirmButtonColor: "#B3995B",
			//         });

			//         console.log("Email Sent", auth.currentUser);
			//       })
			//       .catch((err) => {
			//         Swal.fire({
			//           icon: "error",
			//           title: err.message,
			//           confirmButtonColor: "#B3599B",
			//         });
			//       });
			//   })
			//   .catch((err) => {
			//     console.log(err.message);
			//     setBeatLoader(false);
			//     if (err.message == "Firebase: Error (auth/invalid-email).") {
			//       Swal.fire({
			//         icon: "error",
			//         title: "Invalid Email",
			//         confirmButtonColor: "#B3995B",
			//       });
			//     } else if (
			//       err.message == "Firebase: Error (auth/email-already-in-use)."
			//     ) {
			//       Swal.fire({
			//         icon: "error",
			//         title: "Email In Use",
			//         confirmButtonColor: "#B3995B",
			//       });
			//     } else {
			//       Swal.fire({
			//         icon: "error",
			//         title: err.message,
			//         confirmButtonColor: "#B3995B",
			//       });
			//     }
			//   });
		} else {
			setBeatLoader(false);
			Swal.fire({
				icon: "error",
				title: "Please ensure password matches",
				confirmButtonColor: "#B3995B",
			});
		}
	};

	return (
		<div>
			<div className="intro-header">
				<div
					style={{
						height: "20vh",
						justifyContent: "center",
						display: "flex",
						alignItems: "center",
					}}
				>
					<img src="/LogoStacked.png" alt="" width="200px" />
				</div>

				{/* <h2>Welcome to AUCOVA</h2>
				<h5>Jewellery Asset Management</h5> */}
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					margin: "24px auto 40px",
				}}
			></div>
			<div className="auth-container">
				<div className="auth">
					<input
						type="text"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<label className={`email ${email ? "active" : ""}`}>Email</label>
				</div>
				<div className="auth">
					<input
						type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>
					<label className={`password ${password ? "active" : ""}`}>
						Password
					</label>
				</div>
				<div className="auth">
					<input
						type="password"
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
					/>
					<label
						className={`confirm-password ${confirmPassword ? "active" : ""}`}
					>
						Confirm Password
					</label>
				</div>
				<div className="forgot-password"></div>
				<button
					className="intro-button btn-positive"
					onClick={() => register()}
				>
					{/* eslint eqeqeq: 0 */}
					{beatLoader == true ? <BeatLoader size={8} /> : "Sign Up"}
				</button>
				<div className="auth-footer">
					<span>Already a member? </span>
					<span
						style={{
							textDecoration: "underline",
							color: "var(--primary)",
							paddingLeft: "5px",
						}}
						onClick={() => setState(1)}
					>
						Log In
					</span>
				</div>
			</div>
		</div>
	);
};

export default SignUp;
