import React from "react";
import { BeatLoader } from "react-spinners";

const BaseModal = ({
  children,
  handleTopButton,
  handleBottomButton,
  topButton,
  bottomButton,
  loading,
}) => {
  return (
    <>
      <div className="Modal">
        <div className="Modal-content">
          {children}
          <div className="Modal-footer">
            {topButton && (
              <button
                className="intro-button btn-negative"
                onClick={handleTopButton}
              >
                {loading ? <BeatLoader size={6} /> : topButton}
              </button>
            )}
            {bottomButton && (
              <button
                className="intro-button btn-positive"
                onClick={handleBottomButton}
              >
                {bottomButton}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BaseModal;
