import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { storage } from "../firebase";
import {
  addCertificate,
  addImage,
  addReceipt,
  removeCertificate,
  removeImage,
  removeReceipt,
} from "../redux/uploadSlice";
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";

export const useStorage = () => {
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  // eslint-disable-next-line
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  // console.log(error);
  const uploadToStorage = async (file, type) => {
    console.log(type);
    const acceptableFiles = [
      "jpg",
      "jpeg",
      "png",
      "pdf",
      "doc",
      "heic",
      "heif",
    ];
    console.log(file)
    const getExtension = file.name.split(".").pop().toLowerCase();
    console.log(getExtension)

    const isAcceptable = acceptableFiles.indexOf(getExtension) > -1;

    if (!isAcceptable && (type === "receipts" || type === "certificate")) {
      Swal.fire({
        icon: "error",
        title:
          "Only files with the following extensions are accepted (.jpg, .jpeg, .png, .pdf, .doc, .heic, .heif)",
        confirmButtonColor: "#B3995B",
      });
    } else if (isAcceptable || type === "items") {
      const fileName = `${uuid()}-${file.name}`;
      const storageRef = ref(storage, `${type}/${fileName}`);
      type === "items" && setLoading1(true);
      type === "receipts" && setLoading2(true);
      type === "certificate" && setLoading3(true);
      await uploadBytes(storageRef, file)
        .then(() => getDownloadURL(storageRef))
        .then((url) => {
          type === "items" &&
            dispatch(
              addImage({
                fileName: fileName,
                fileUrl: url,
              })
            );
          type === "receipts" &&
            dispatch(
              addReceipt({
                fileName: fileName,
                fileUrl: url,
              })
            );
          type === "certificate" &&
            dispatch(
              addCertificate({
                fileName: fileName,
                fileUrl: url,
              })
            );
          Swal.fire({
            icon: "success",
            title: "Photo Uploaded",
            showConfirmButton: false,
            timer: 1000,
          });
          type === "items" && setLoading1(false);
          type === "receipts" && setLoading2(false);
          type === "certificate" && setLoading3(false);
        })
        .catch((err) => {
          setError(err.message);
          type === "items" && setLoading1(false);
          type === "receipts" && setLoading2(false);
          type === "certificate" && setLoading3(false);
        });
    }
  };

  const deleteFromStorage = async (fileName, type, removeIndex) => {
    const imageRef = ref(storage, `${type}/${fileName}`);

    type === "items" && setLoading1(true);
    type === "receipts" && setLoading2(true);
    type === "certificate" && setLoading3(true);
    deleteObject(imageRef)
      .then(() => {
        type === "items" && dispatch(removeImage(removeIndex));
        type === "receipts" && dispatch(removeReceipt(removeIndex));
        type === "certificate" && dispatch(removeCertificate(removeIndex));
      })
      .then(() => {
        type === "items" && setLoading1(false);
        type === "receipts" && setLoading2(false);
        type === "certificate" && setLoading3(false);
      })
      .catch((err) => {
        setError(err.message);
        type === "items" && setLoading1(false);
        type === "receipts" && setLoading2(false);
        type === "certificate" && setLoading3(false);
      });
  };
  return { uploadToStorage, deleteFromStorage, loading1, loading2, loading3 };
};
