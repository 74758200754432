import React from "react";

export default function Header({ title, rightButton, leftButton }) {
  return (
    <nav className=" navbar navbar-expand-lg navbar-light">
      <div className="container">
        {leftButton}
        <div className="navbar-title mx-auto mt-1">{title}</div>
        {rightButton}
      </div>
    </nav>
  );
}
