import React, { useEffect } from "react";
import BaseModal from "./BaseModal";
import "./Modal.css";

const CancelModal = ({ show, onClose, onDiscard }) => {
  useEffect(() => {
    document.body.style = show ? "overflow: hidden !important" : "";
  }, [show]);
  return (
    <>
      {show && (
        <BaseModal
          handleTopButton={onDiscard}
          handleBottomButton={onClose}
          topButton="Discard"
          bottomButton="Cancel"
        >
          <div className="Modal-header">
            <div className="modal-title">Discard this Entry?</div>
          </div>
          <div className="modal-body">
            <h5>Your changes will not be saved.</h5>
            <h5 style={{ padding: "1.5rem 1rem" }}>
              Save your entry to view it privately or edit it again later.
            </h5>
          </div>
        </BaseModal>
      )}
    </>
  );
};

export default CancelModal;
