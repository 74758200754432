import React, { useState, useRef, useEffect, useContext } from "react";
import Header from "../../components/Header";
import MenuButton from "../../components/MenuButton";
import Menu from "../../components/Menu";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
// eslint-disable-next-line no-unused-vars
import { auth, db, storage } from "../../firebase";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import Swal from "sweetalert2";
import axios from "axios";
import { AuthContext } from "../../context/AuthContext";

import Switch from "@mui/material/Switch";
import { FormControlLabel } from "@mui/material";
import {
  EmailAuthProvider,
  // EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
  // reauthenticateWithCredential,
  // updatePassword,
} from "firebase/auth";
import Modal from "../../components/Modal/UploadModal/ProfileUploadModal";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../components/LoadingScreen";
import { BeatLoader } from "react-spinners";
import CancelModal from "../../components/Modal/PopupModal/CancelModal";
import { useFileCompressor } from "../../hooks/useFileCompressor";

function Settings() {
  const [visible, setVisible] = useState(false);
  const [userData, setUserData] = useState(null);
  const [currentPassword, setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [showUpload, setShowUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [beatLoader, setBeatLoader] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [imageUploader, setImageUploader] = useState(false);

  const { isStatus, setIsStatus } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleMouseDown = () => {
    setVisible(!visible);
  };

  const profileRef = useRef(null);
  const { fileCompressor } = useFileCompressor();
  // console.log(userData);

  const addProfilePic = async (e) => {
    setImageUploader(true);

    if (userData.profilePic) {
      setUserData((prev) => {
        return { ...prev, profilePic: null };
      });

      const deleteImageRef = ref(
        storage,
        `profilePic/${userData.profilePic.name}`
      );
      deleteObject(deleteImageRef)
        .then(() => {
          console.log("Image has been deleted");
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const file = e.target.files[0];

    const compressedImage = await fileCompressor(file);

    const imagesRef = ref(storage, `profilePic/${compressedImage.name}`);
    uploadBytes(imagesRef, compressedImage)
      .then(() => getDownloadURL(imagesRef))
      .then((url) => {
        setUserData((prev) => {
          return {
            ...prev,
            profilePic: {
              name: compressedImage.name,
              url: url,
            },
          };
        });
        setImageUploader(false);
      });
  };
  const removeProfilePic = () => {
    setUserData((prev) => {
      return { ...prev, profilePic: null };
    });
    const deleteImageRef = ref(
      storage,
      `profilePic/${userData.profilePic.name}`
    );
    deleteObject(deleteImageRef);
  };

  const user = getAuth().currentUser;

  // SHOW DISCARD MODAL
  const showModal = (e) => {
    e.preventDefault();
    setModalState(true);
  };

  // SAVE DISCARD MODAL
  const onDiscard = (e) => {
    e.preventDefault();
    navigate("/");
  };

  // CLOSE DISCARD MODAL
  const onClose = (e) => {
    e.preventDefault();
    setModalState(false);
  };

  const handleChange = (e) => {
    setUserData((prev) => {
      return { ...prev, username: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBeatLoader(true);

    try {
      updateProfileEmail();
      updateProfileData();
      if (currentPassword && newPassword && confirmPassword) {
        updateProfilePassword();
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: err.message,
        confirmButtonColor: "#B3995B",
      });
    }
  };

  // ****************** UPDATE EMAIL ******************

  // eslint-disable-next-line no-unused-vars
  const updateProfileEmail = async () => {
    if (!userData.email) {
      Swal.fire({
        icon: "error",
        title: "The email address cannot be blank.",
        confirmButtonColor: "#B3995B",
      });
    } else {
      const emailErrors = [
        "auth/email-already-exists",
        "auth/invalid-email",
        "Your email has been updated.",
      ];

      const token = await auth.currentUser.getIdToken();
      // console.log(token);

      axios
        .post(
          `${process.env.REACT_APP_URL}/${process.env.REACT_APP_UPDATE_EMAIL}`,
          {
            email: userData.email,
            uid: userData.uid,
          },
          {
            headers: { AuthToken: token },
          }
        )
        .then((res) => {
          console.log(res.data);

          if (res.data.code == emailErrors[0]) {
            setBeatLoader(false);
            Swal.fire({
              icon: "error",
              title: "The existing email is in use.",
              confirmButtonColor: "#B3995B",
            });
          } else if (res.data.code == emailErrors[1]) {
            setBeatLoader(false);
            Swal.fire({
              icon: "error",
              title: "The email address is improperly formatted.",
              confirmButtonColor: "#B3995B",
            });
          } else {
            console.log("UPDATED EMAIL: " + res.data.email);

            updateDoc(doc(db, "users", user.uid), {
              email: userData.email,
            });

            Swal.fire({
              icon: "success",
              title: "Your email has been updated.",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: err.message,
            confirmButtonColor: "#B3995B",
          });
        });
    }
  };

  // ****************** UPDATE DB ******************
  const updateProfileData = () => {
    const usernameFeedback = [
      "Username cannot be blank",
      "Username cannot contain special characters",
    ];

    //eslint-disable-next-line
    const invalidChar = /[!@#$%^&*()+\-=\[\]{};':"\\|,<>\/? ]+/;

    //eslint-disable-next-line
    if (userData.username == "") {
      setBeatLoader(false);
      Swal.fire({
        icon: "error",
        title: usernameFeedback[0],
        showConfirmButton: false,
        timer: 1500,
      });
      throw new Error(usernameFeedback[0]);
    } else if (userData.username.match(invalidChar)) {
      setBeatLoader(false);
      Swal.fire({
        icon: "error",
        title: usernameFeedback[1],
        showConfirmButton: false,
        timer: 1500,
      });
      throw new Error(usernameFeedback[1]);
    } else {
      updateDoc(doc(db, "users", user.uid), {
        username: userData.username,
        fullname: userData.fullname,
        subscription: userData.subscription,
        profilePic: { ...userData.profilePic },
      }).then(() => {
        setBeatLoader(false);
        console.log("PROFILE UPDATED");
      });
    }
  };

  const userFeedback = [
    "Password cannot be blank",
    "Username cannot be blank",
    "Username cannot contain special characters",
    "Password do not match",
    "Account successfully created",
    "Email cannot be blank",
  ];

  // UPDATE PASSWORD V2
  const updateProfilePassword = async () => {
    if (!currentPassword || !newPassword || !confirmPassword) {
      Swal.fire({
        icon: "error",
        title: "The password field cannot be blank",
        confirmButtonColor: "#B3995B",
      });
    } else {
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        currentPassword
      );

      await reauthenticateWithCredential(auth.currentUser, credential)
        .then(() => {
          if (newPassword === confirmPassword) {
            // Password matches
            // Update password
            return updatePassword(auth.currentUser, newPassword)
              .then(() => {
                setBeatLoader(false);
                Swal.fire({
                  icon: "success",
                  title: "Your password has been updated.",
                  showConfirmButton: false,
                  timer: 1500,
                }).then(() => navigate("/"));
              })
              .catch((err) => {
                setBeatLoader(false);
                Swal.fire({
                  icon: "error",
                  title: err.message,
                  showConfirmButton: false,
                  timer: 1500,
                });
              });
          } else {
            setBeatLoader(false);
            Swal.fire({
              icon: "error",
              title: userFeedback[3],
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          setBeatLoader(false);
          Swal.fire({
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    }
  };

  const fetchUserData = async () => {
    setLoading(true);

    const usersRef = collection(db, "users");
    const q = query(usersRef, where("uid", "==", user.uid));
    // console.log(user.uid);

    const res = await getDocs(q);

    res.forEach((doc) => {
      setUserData(doc.data());
      setLoading(false);
    });
  };

  useEffect(() => {
    if (user) {
      fetchUserData();
      // console.log(process.env);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <Header
        title="AUCOVA"
        leftButton={<MenuButton handleMouseDown={handleMouseDown} />}
      />

      <Menu menuVisibility={visible} handleMouseDown={handleMouseDown} />
      <Modal
        showUpload={showUpload}
        setShowUpload={setShowUpload}
        profileRef={profileRef}
        removeProfilePic={removeProfilePic}
      />

      {/* eslint eqeqeq: 0 */}
      {loading == true ? (
        <LoadingScreen />
      ) : (
        <>
          {userData && (
            <form onSubmit={handleSubmit}>
              <div className="setup-container">
                <div className="intro-header setup">
                  <h2>Settings</h2>
                  <div className="settings-container">
                    <div className="settings-inner-container">
                      <b>Profile</b>
                    </div>
                    <div className="intro-header setup">
                      <div className="setup-profile">
                        {userData.profilePic ? (
                          <>
                            {Object.keys(userData.profilePic).length > 0 ? (
                              <img
                                src={userData.profilePic.url}
                                alt="img"
                                className="profile-pic"
                              />
                            ) : (
                              <img src="/fixed/DefaultProfile.svg" alt="" />
                            )}
                          </>
                        ) : (
                          <>
                            {imageUploader == false && (
                              <img src="/fixed/DefaultProfile.svg" alt="" />
                            )}
                          </>
                        )}

                        {imageUploader === true ? <BeatLoader size={8} /> : ""}
                      </div>
                      <p
                        style={{ textDecoration: "underline" }}
                        onClick={() => {
                          userData.profilePic
                            ? setShowUpload(true)
                            : profileRef.current.click();
                        }}
                      >
                        Upload profile picture
                      </p>
                    </div>

                    <input
                      type="file"
                      ref={profileRef}
                      hidden
                      onChange={addProfilePic}
                    />
                    <div className="settings-form-container">
                      <div className="settings">
                        <input
                          autoComplete="off"
                          type="text"
                          value={userData.username}
                          name="username"
                          onChange={handleChange}
                        />
                        <label className={userData.username ? "active" : ""}>
                          Username
                        </label>
                      </div>
                      <p>
                        Usernames can only use letters, numbers, underscores and
                        periods.
                      </p>
                      <div className="settings">
                        <input
                          autoComplete="off"
                          type="text"
                          name="fullname"
                          value={userData.fullname}
                          onChange={(e) =>
                            setUserData((prev) => {
                              return { ...prev, fullname: e.target.value };
                            })
                          }
                        />
                        <label className={userData.fullname ? "active" : ""}>
                          Full Name (hidden from public)
                        </label>
                      </div>
                      <div className="settings" style={{ marginTop: "10px" }}>
                        <input
                          autoComplete="off"
                          type="text"
                          value={userData.email}
                          name="email"
                          onChange={(e) => {
                            setUserData((prev) => {
                              return { ...prev, email: e.target.value };
                            });
                          }}
                        />
                        <label className={userData.email ? "active" : ""}>
                          Email Address
                        </label>
                      </div>
                      <div style={{ margin: "64px 0" }} />
                      <div className="settings">
                        <input
                          autoComplete="off"
                          type="password"
                          onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                        <label className={currentPassword ? "active" : ""}>
                          Current Password
                        </label>
                      </div>
                      <div className="settings">
                        <input
                          autoComplete="off"
                          type="password"
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <label className={newPassword ? "active" : ""}>
                          New Password
                        </label>
                      </div>

                      <div className="settings">
                        <input
                          autoComplete="off"
                          type="password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <label className={newPassword ? "active" : ""}>
                          Confirm Password
                        </label>
                      </div>
                    </div>
                  </div>
                  <div style={{ margin: "27px 0" }} />
                  <div className="settings-container">
                    <div className="settings-inner-container">
                      <b>Events</b>
                      <h5>
                        Would you like to be contacted via the email above when
                        we hold events?
                      </h5>
                      <p>
                        From educational talks to expert opinions on collector
                        trends and special sales showcase on collectibles.
                      </p>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={userData.subscription}
                            onChange={() =>
                              setUserData((prev) => {
                                return {
                                  ...prev,
                                  subscription: !userData.subscription,
                                };
                              })
                            }
                            sx={{
                              "& .MuiSwitch-switchBase": {
                                color: "#b3995b",
                              },
                              "& .MuiSwitch-track": {
                                backgroundColor: "#b3995b",
                              },
                            }}
                          />
                        }
                        label={userData.subscription ? "Yes" : "No"}
                      />
                    </div>
                  </div>

                  {isStatus.code === "BIOMETRIC_DISABLED" &&
                  isStatus.timeOutAllowed === false &&
                  isStatus.docRefID ? (
                    <div className="settings-container">
                      <div className="settings-inner-container">
                        <b>Security</b>
                        <h5>
                          Would you like to enable Biometric Authentication?
                        </h5>
                        <p>This will enable Touch ID or Face ID</p>
                        <button
                          className="button btn-positive"
                          style={{ width: "50%", marginTop: "15px" }}
                          onClick={() => {
                            setIsStatus({
                              ...isStatus,
                              code: "BIOMETRIC_NOT_AUTHENTICATED",
                              timeOutAllowed: "false",
                              updateData: true,
                            });
                            navigate("/reauth");
                          }}
                        >
                          <h6>Register</h6>
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>

                {modalState == true ? (
                  <CancelModal
                    show={true}
                    onDiscard={onDiscard}
                    onClose={onClose}
                  />
                ) : (
                  ""
                )}
                <div className="settings-footer">
                  {" "}
                  <button className="button btn-negative" onClick={showModal}>
                    Discard
                  </button>
                  <button className="button btn-positive">
                    {beatLoader == true ? (
                      <BeatLoader size={8} />
                    ) : (
                      "Save Changes"
                    )}
                  </button>
                </div>
              </div>
            </form>
          )}
        </>
      )}
    </>
  );
}

export default Settings;
