import React, { useEffect } from "react";

import "./Modal.css";

function CategoryModal({ show, setCategory, onClose, header, categories }) {
  useEffect(() => {
    document.body.style = show ? "overflow: hidden !important" : "";
  }, [show]);

  return (
    <div className={`categorymenu ${show ? "show" : "hide"}`}>
      <div className="categorymenu-header">
        <div className="categorymenu-title">
          <h4> {header}</h4>
        </div>

        <img
          src="/fixed/CloseButtonGold.svg"
          alt=""
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        />
      </div>
      <ul className="navbar-nav mb-2 mb-lg-0">
        {categories.map((cat) => {
          return (
            <li
              className="nav-item"
              onClick={() => {
                setCategory(cat);
                onClose();
              }}
              value={cat}
            >
              <h5>{cat}</h5>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default CategoryModal;
