import React, { useEffect } from "react";
import BaseModal from "./BaseModal";
import "./Modal.css";

const TermsModal = ({ show, onClose }) => {
  useEffect(() => {
    document.body.style = show ? "overflow: hidden !important" : "";
  }, [show]);
  return (
    <>
      {show && (
        <BaseModal handleBottomButton={onClose} bottomButton="Close">
          <div className="Modal-header">
            <div
              className="modal-title"
              style={{
                fontFamily: "Old Standard TT",
                fontWeight: "700",
                fontSize: "24px",
              }}
            >
              Terms & Conditions
            </div>
          </div>
          <div className="modal-body" style={{ textAlign: "start" }}>
            <h5 style={{ padding: "0 0 2rem" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa dui
              nisl, diam nunc rutrum tortor sagittis, pellentesque in. Id ligula
              diam non varius montes. Eu urna eu, nascetur laoreet. Vel habitant
              id risus amet. Commodo nisl faucibus morbi non lorem tristique
              lorem pretium. Mattis aliquet.
            </h5>
          </div>
        </BaseModal>
      )}
    </>
  );
};

export default TermsModal;
