import React, {
  useState,
  useRef,
  createRef,
  useContext,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import NewEntry2 from "./NewEntry2";
import NewEntry3 from "./NewEntry3";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LongMenuEntry from "../../components/LongMenu/LongMenuEntry";
import { updateDoc, doc, arrayUnion, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { FormContext } from "../../context/FormContext";
import CancelModal from "../../components/Modal/PopupModal/CancelModal";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import { Box, IconButton } from "@mui/material";
import { v4 as uuid } from "uuid";
import { useDispatch } from "react-redux";
import {
  selectInput,
  addCategory,
  selectImage,
  resetInput,
} from "../../redux/uploadSlice";
import { useSelector } from "react-redux";
import { AuthContext } from "../../context/AuthContext";

function NewEntry() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(1);
  const user = JSON.parse(localStorage.getItem("user"));
  const [showMenu, setShowMenu] = useState(false);

  const [categoryClicked, setCategoryClicked] = useState(null);

  // eslint-disable-next-line
  const { isStatus, setIsStatus } = useContext(AuthContext);

  const dispatch = useDispatch();
  const images = useSelector(selectImage);
  const input = useSelector(selectInput);

  const navigate = useNavigate();

  const onDiscard = (e) => {
    e.preventDefault();
    dispatch(resetInput());

    if (isStatus.code === "BIOMETRIC_DISABLED") {
      setIsStatus({
        ...isStatus,
      });
    } else {
      setIsStatus({
        ...isStatus,
        timeOutAllowed: true,
      });
    }

    navigate("/portfolio");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (input.title == null || input.title == "" || input.title.trim() === "") {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Please fill in the title",
        confirmButtonColor: "#B3995B",
      });
    } else if (input.img.length === 0) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Please upload at least one photo.",
        confirmButtonColor: "#B3995B",
      });
    } else {
      const id = uuid();
      await setDoc(doc(db, "items", id), {
        id: id,
        ...input,
      });

      updateDoc(doc(db, "users", user.uid), {
        items: arrayUnion(id),
      }).then(() => {
        setLoading(false);

        if (isStatus.code === "BIOMETRIC_DISABLED") {
          setIsStatus({
            ...isStatus,
          });
        } else {
          setIsStatus({
            ...isStatus,
            timeOutAllowed: true,
          });
        }

        Swal.fire({
          icon: "success",
          title: "Your entry has been added!",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/portfolio");
        dispatch(resetInput());
      });
    }
  };

  const categories = [
    "Rings",
    "Earrings",
    "Pendants",
    "Bracelets",
    "Brooches",
    "Gems",
    "Others",
  ];

  const categoryRefs = useRef([]);
  categoryRefs.current = categories.map(
    (_, i) => categoryRefs.current[i] ?? createRef()
  );
  console.log(categoryRefs.current);
  const categoryOnClick = (e) => {
    dispatch(addCategory(e.target.value));
  };

  return (
    <div>
      <FormContext.Provider
        value={{
          setShow,
        }}
      >
        <CancelModal
          show={show}
          onClose={() => setShow(false)}
          onSave={handleSubmit}
          onDiscard={onDiscard}
        />
        <form>
          {state === 1 && (
            <>
              <Header
                title="New Entry"
                leftButton={
                  <img
                    src="/fixed/Vector.png"
                    alt="img"
                    onClick={() => setShow(true)}
                  />
                }
              />
              <Box display="flex" justifyContent="center">
                <Box margin="22px 54px 29px" textAlign="center">
                  <h3 style={{ fontFamily: "Avenir Regular" }}>
                    What will you add
                  </h3>
                  <h3 style={{ fontFamily: "Avenir Regular" }}>
                    to your porfolio today?
                  </h3>
                </Box>
              </Box>

              <div className="categories-container">
                {categories.map((category, index) => {
                  return (
                    <>
                      <div
                        className="category"
                        onClick={(e) => {
                          categoryRefs.current[index].current.click();
                          setState(2);
                        }}
                      >
                        <img
                          src={`/categories/${category}.svg`}
                          alt="img"
                        ></img>
                      </div>
                      <input
                        type="radio"
                        value={category}
                        ref={categoryRefs.current[index]}
                        hidden
                        onChange={categoryOnClick}
                      />
                    </>
                  );
                })}
              </div>
            </>
          )}
          {state === 2 && (
            <>
              <Header
                title="New Entry"
                leftButton={
                  <img
                    src="/fixed/Back.svg"
                    alt="img"
                    onClick={() => {
                      if (isStatus.code === "BIOMETRIC_DISABLED") {
                        setIsStatus({
                          ...isStatus,
                        });
                      } else {
                        setIsStatus({
                          ...isStatus,
                          timeOutAllowed: true,
                        });
                      }

                      setState(1);
                    }}
                  />
                }
                rightButton={
                  <img
                    src="/fixed/Next.svg"
                    alt="img"
                    onClick={() => {
                      if (images.length < 1) {
                        Swal.fire({
                          icon: "error",
                          title: "Please upload at least one photo",
                          confirmButtonColor: "#B3995B",
                        });
                      } else {
                        setState(3);
                      }
                    }}
                  />
                }
              />
              <NewEntry2 category={categoryClicked} />
            </>
          )}
          {state === 3 && (
            <>
              <Header
                title="Add Details"
                leftButton={
                  <img
                    src="/fixed/Vector.png"
                    alt="img"
                    onClick={() => {
                      setShow(true);
                    }}
                  />
                }
                rightButton={
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={showMenu ? "long-menu" : undefined}
                    aria-expanded={showMenu ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={() => setShowMenu(true)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                }
              />
              <LongMenuEntry
                handleSubmit={handleSubmit}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
              />
              <NewEntry3
                category={categoryClicked}
                setCategory={setCategoryClicked}
              />
              <button className="saveBtn" onClick={handleSubmit} type="submit">
                {/* eslint eqeqeq: 0 */}
                {loading == true ? (
                  <BeatLoader size={8} />
                ) : (
                  <h5 style={{ fontFamily: "Avenir Bold" }}>Save</h5>
                )}
              </button>
            </>
          )}
        </form>
      </FormContext.Provider>
    </div>
  );
}

export default NewEntry;
