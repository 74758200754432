import React, { useState } from "react";
import Header from "../../components/Header";
import MenuButton from "../../components/MenuButton";
import Menu from "../../components/Menu";
import LogIn from "./LogIn";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";
import ForgotPasswordSuccess from "./ForgotPasswordSuccess";

function Intro() {
	const [visible, setVisible] = useState(false);
	const [state, setState] = useState(0);
	const handleMouseDown = () => {
		setVisible(!visible);
	};
	return (
		<div>
			<Header
				title="AUCOVA"
				leftButton={<MenuButton handleMouseDown={handleMouseDown} />}
			/>
			<Menu menuVisibility={visible} handleMouseDown={handleMouseDown} />

			<div className="intro-container">
				{state === 0 && (
					<>
						<div className="intro-header">
							<div
								style={{
									height: "20vh",
									justifyContent: "center",
									display: "flex",
									alignItems: "center",
								}}
							>
								<img src="/LogoStacked.png" alt="" width="200px" />
							</div>
							{/* 
							<h2>Welcome to AUCOVA</h2>
							<h5>Jewellery Asset Management</h5> */}
						</div>

						<div
							style={{
								display: "flex",
								justifyContent: "center",
								margin: "24px auto 40px",
							}}
						>
							<img src="/fixed/Line.svg" alt="" />
						</div>
						<div
							style={{
								textAlign: "center",
							}}
						>
							<h5>
								Fine jewellery is the most emotive work of art any collector can
								ever own. Worn next to the skin & often marking significant life
								events, they are glittering with gemstones that are billions of
								years old and handed down from generation to generation.
							</h5>
							<br />
							<h5>
								AUCOVA helps Jewellery Collectors manage their collections by
								providing the secure technology, services & experts to assist in
								cataloging, maintaining, acquiring & trading their collections.
							</h5>
						</div>

						<div
							style={{
								display: "flex",
								alignItems: "center",
								height: "196px",
							}}
						>
							<div className="intro-footer">
								<button
									className="intro-button btn-positive"
									onClick={() => setState(1)}
								>
									Log In
								</button>
								<div style={{ margin: "8px 0 " }}></div>
								<button
									className="intro-button btn-positive"
									onClick={() => setState(2)}
								>
									Sign Up
								</button>
							</div>
						</div>
					</>
				)}
				{state === 1 && <LogIn setState={setState} />}
				{state === 2 && <SignUp setState={setState} />}
				{state === 3 && <ForgotPassword setState={setState} />}
				{state === 4 && <ForgotPasswordSuccess setState={setState} />}
			</div>
		</div>
	);
}

export default Intro;
