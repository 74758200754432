import React from "react";
import { Link } from "react-router-dom";

const ItemContainer = ({ items }) => {
  return (
    <div class="container">
      {items.length > 0 && (
        <div class="row gy-4 gx-4 row-cols-2">
          {items.map((item) => {
            const { id, img } = item;
            return (
              <div className="col" key={id}>
                <Link to={`/item/${id}`}>
                  <img
                    style={{
                      minHeight: "200px",
                      minWidth: "160px",
                      maxHeight: "25vh",
                      maxWidth: "50vw",
                      objectFit: "contain",
                    }}
                    alt="img"
                    src={img[0].fileUrl}
                  />
                  {item.assessed && (
                    <img
                      className="assessed-icon"
                      src="/fixed/Assessed.svg"
                      alt="img"
                    ></img>
                  )}
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ItemContainer;
