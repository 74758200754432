import Menu from "../../components/Menu";
import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import MenuButton from "../../components/MenuButton";
import Multiselect from "multiselect-react-dropdown";
import Swal from "sweetalert2";
import { v4 as uuid } from "uuid";
import { auth, db } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";
import { useLocalStorage } from "../../hooks/useLocalStorage";

function ContactForm() {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  // eslint-disable-next-line
  const [userCred, setUserCred] = useLocalStorage("user", null);
  const [form, setForm] = useState({
    formId: uuid(),
    name: "",
    mobile: "",
    email: "",
    services: "",
  });
  const [service, selectService] = useState([
    { name: "Jewellery Assessment", id: 1 },
    { name: "Certification", id: 2 },
    { name: "Insurance", id: 3 },
    { name: "Asset Enhancement/Maintenance", id: 4 },
    { name: "Sales Listing Assistance", id: 5 },
    { name: "Gold Trade In", id: 6 },
  ]);

  useEffect(() => {
    // console.log(process.env);
  }, []);

  const handleMouseDown = () => {
    setVisible(!visible);
  };

  // const resetRef = useRef();
  // const resetValues = () => {
  //   resetRef.current.resetSelectedValues();
  // };

  const onSelect = (selectedItem) => {
    setForm({
      ...form,
      services: selectedItem,
    });
    console.log(form);
  };

  const onRemove = (removeItem) => {
    console.log(removeItem);
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // const validRegex =
    //   /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    // } else if (!form.email.match(validRegex)) {
    //   setLoading(false);
    //   Swal.fire({
    //     icon: "error",
    //     title: "The email entered is invalid",
    //     confirmButtonColor: "#B3995B",
    //   });

    /* eslint eqeqeq: 0 */
    if (form.services == "" || form.mobile == "") {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Please fill in the required fields",
        confirmButtonColor: "#B3995B",
      });
    } else {
      const token = await auth.currentUser.getIdToken();
      console.log(token);

      // submitForm API
      axios
        .post(
          `${process.env.REACT_APP_URL}/${process.env.REACT_APP_SUBMIT_FORM}`,
          {
            services: form.services,
            name: userCred.username,
            number: form.mobile,
            email: userCred.email,
          },
          {
            headers: { AuthToken: token },
          }
        )
        .then((res) => {
          console.log(res);
          setSubmitted(true);
          Swal.fire({
            icon: "success",
            title: "Form Submitted.",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((err) => {
          setLoading(false);
          setSubmitted(false);
          console.log(err);
          Swal.fire({
            icon: "error",
            title: err.message,
            confirmButtonColor: "#B3995B",
          });
        });

      // Send to firebase
      await setDoc(doc(db, "contactform", uuid()), {
        ...form,
      });
    }
  };

  return (
    <>
      <Header
        title="AUCOVA"
        leftButton={<MenuButton handleMouseDown={handleMouseDown} />}
      />
      <Menu menuVisibility={visible} handleMouseDown={handleMouseDown} />

      <div class="ContactForm-container">
        {submitted == false ? (
          <>
            <div class="ContactForm-header">
              <h2>Request for Services</h2>
            </div>

            <h5>
              Please select the services you require in managing your fine
              Jewellery portfolio & provide us with the contact details so that
              our service representative can contact you.​
            </h5>

            <h5>
              Professional charges may apply with the services chosen. We look
              forward to serving you.
            </h5>
            <form class="ContactForm-form" onSubmit={handleSubmit}>
              <div class="formInput2">
                <Multiselect
                  showArrow={true}
                  // ref={resetRef}
                  placeholder="Services required"
                  options={service}
                  selectedValues={selectService}
                  onSelect={onSelect}
                  onRemove={onRemove}
                  displayValue="name"
                  avoidHighlightFirstOption={true}
                  closeIcon="cancel"
                />
              </div>

              <div class="formInput2">
                <div class="form-container">
                  <input
                    autocomplete="off"
                    name="name"
                    type="text"
                    placeholder={userCred.username}
                    value={form.name}
                    onChange={handleChange}
                    disabled
                  />
                </div>

                <div class="form-container">
                  <input
                    autocomplete="off"
                    name="email"
                    placeholder="Email address"
                    value={userCred.email}
                    onChange={handleChange}
                    disabled
                  />
                </div>

                <div class="form-container">
                  <input
                    autocomplete="off"
                    name="mobile"
                    type="number"
                    placeholder="Mobile"
                    value={form.mobile}
                    onChange={handleChange}
                    pattern="\d*"
                  />
                </div>
              </div>
              <div>
                <label>
                  We value your privacy and will use your details solely for
                  responding to your request and verification.​
                </label>
              </div>

              <button className="actionBtn">
                {loading == true ? (
                  <BeatLoader size={8} />
                ) : (
                  <h5 style={{ fontFamily: "Avenir Bold", margin: "0" }}>
                    Submit
                  </h5>
                )}
              </button>
            </form>
          </>
        ) : (
          <div className="thank-you">
            <label>
              <h3>Thank you for contacting AUCOVA.​</h3>
              An AUCOVA service representative will be in touch with you to
              better understand your requests within 1 – 3 days via the mobile
              number you have provided.
            </label>
            <img src="/fixed/Line.svg" alt="" />
            <p>We look forward to serving you personally then.​</p>
          </div>
        )}
      </div>
    </>
  );
}

export default ContactForm;
