import React, { useEffect } from "react";
import "./Modal.css";
import BaseModal from "./BaseModal";

const LogOutModal = ({ loadingState, hideCancelModal, modalLogOut, show }) => {
  useEffect(() => {
    document.body.style = show ? "overflow: hidden !important" : "";
  }, [show]);
  return (
    <>
      {show && (
        <BaseModal
          handleTopButton={modalLogOut}
          handleBottomButton={hideCancelModal}
          topButton="Log Out"
          bottomButton="Cancel"
          loading={loadingState}
        >
          <div className="Modal-header">
            <div className="modal-title">Proceed to logout?</div>
          </div>
          <div className="modal-body">
            <h5 style={{ padding: "0 0 2rem" }}>
              You will have to sign in again
            </h5>
          </div>
        </BaseModal>
      )}
    </>
  );
};

export default LogOutModal;
