import React from "react";
import { AnimateKeyframes } from "react-simple-animate";
import faceid from "../images/faceid.svg";

const AnimateImg = () => {
  return (
    <>
      <AnimateKeyframes
        play
        iterationCount="infinite"
        duration={2}
        keyframes={[
          "transform: translateY(0))",
          "transform: translateY(-20px)",
          "transform: translateY(0)",
        ]}
      >
        <img src={faceid} alt="faceid" />
      </AnimateKeyframes>
      {/* <img src={faceid} alt='faceid' /> */}
    </>
  );
};

export default AnimateImg;
