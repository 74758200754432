import { getAuth, signOut } from "firebase/auth";
import React, { useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { auth } from "../firebase";
import { logout } from "../redux/authSlice";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { AuthContext } from "../context/AuthContext";
import LogOutModal from "./Modal/PopupModal/LogOutModal";

const Menu = ({ handleMouseDown, menuVisibility }) => {
	// eslint-disable-next-line no-unused-vars
	const [active, setActive] = useState(null);

	const [showModal, setShowModal] = useState(false);
	const [loadingState, setLoadingState] = useState(false);
	// eslint-disable-next-line
	const [data, setData] = useLocalStorage("authData", null);

	// eslint-disable-next-line
	const { status, setIsStatus } = useContext(AuthContext);

	const user = getAuth().currentUser;
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const handleAuthentication = () => {
		if (user) {
			setShowModal(true);
			handleMouseDown();
		} else {
			navigate("/intro");
		}
	};

	const modalLogOut = () => {
		if (user) {
			setLoadingState(true);
			dispatch(logout());
			signOut(auth).then(() => {
				setData(null);
				setIsStatus(null);
				setLoadingState(false);
				navigate("/intro");
			});
		}
	};

	const hideCancelModal = () => {
		// hide modal
		setShowModal(false);
	};

	const id = useParams();
	useEffect(() => {
		document.body.style = menuVisibility ? "overflow: hidden !important" : "";
	}, [menuVisibility]);

	useEffect(() => {
		setActive(id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadingState, id]);
	return (
		<>
			<LogOutModal
				hideCancelModal={hideCancelModal}
				modalLogOut={modalLogOut}
				loadingState={loadingState}
				show={showModal}
			/>

			<div
				className={`menu ${menuVisibility ? "show" : "hide"}`}
				id="flyoutMenu"
			>
				{/* eslint eqeqeq: 0 */}

				<div className="menuheader">
					<button
						onClick={handleMouseDown}
						className="position-absolute menu-close btn "
					>
						<img src="/fixed/CloseButtonGold.svg" alt="" />
					</button>
					<img src="/LogoStacked.png" alt="" width="200px" />
					{/* <h1>AUCOVA</h1>
					<h5>Jewellery Asset Management</h5> */}
				</div>

				<ul className="navbar-nav  mb-2 mb-lg-0">
					<NavLink
						className={({ isActive }) => (isActive ? "activeStyle" : undefined)}
						to="/"
					>
						<li className="nav-item">
							<h5>Home</h5>
						</li>
					</NavLink>
					<NavLink
						className={({ isActive }) => (isActive ? "activeStyle" : undefined)}
						to="/about"
					>
						<li className="nav-item">
							<h5>About</h5>
						</li>
					</NavLink>
					<NavLink
						className={({ isActive }) => (isActive ? "activeStyle" : undefined)}
						to="/portfolio"
					>
						<li className="nav-item">
							<h5>My Portfolio</h5>
						</li>
					</NavLink>

					<NavLink
						className={({ isActive }) => (isActive ? "activeStyle" : undefined)}
						to="/contactform"
					>
						<li className="nav-item">
							<h5>Request for Services</h5>
						</li>
					</NavLink>
					<div style={{ margin: "27px" }}></div>
					<NavLink
						className={({ isActive }) => (isActive ? "activeStyle" : undefined)}
						to="/settings"
					>
						<li className="nav-item">
							<h5>Settings</h5>
						</li>
					</NavLink>
					<li className="nav-item" onClick={() => handleAuthentication()}>
						<h5>{user ? "Log out" : "Log In"}</h5>
					</li>
				</ul>
			</div>
		</>
	);
};

export default Menu;
