import React, { useState, useContext } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import Swal from "sweetalert2";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { AuthContext } from "../../context/AuthContext";
import { getDocs, query, where, collection } from "firebase/firestore";
import { db } from "../../firebase";

function LogIn({ setState }) {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [userCred, setUserCred] = useLocalStorage("user", null);
  // eslint-disable-next-line
  const { isStatus, setIsStatus } = useContext(AuthContext);

  const logIn = () => {
    setLoading(true);
    // setIsStatus({ code: "BIOMETRIC_TIME_OUT", timeOutAllowed: true });

    setIsStatus({
      code: "BIOMETRIC_DISABLED",
      timeOutAllowed: false,
    });

    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        navigate("/loading");

        console.log(user);
        if (user) {
          setUserCred({
            username: user.displayName,
            email: user.email,
            // verificationStatus: user.emailVerified,
            uid: user.uid,
          });

          console.log(user);

          const q = query(
            collection(db, "userBiometricStatus"),
            where("uid", "==", user.uid)
          );

          const q2 = query(
            collection(db, "globalBiometric"),
            where("id", "==", "yexfdofnfkstzzmojittpvdargrxloki")
          );

          const querySnapshot = await getDocs(q);

          const querySnapShot2 = await getDocs(q2);

          querySnapshot.forEach((doc) => {
            console.log(doc.data().biometricEnabled);

            querySnapShot2.forEach((doc2) => {
              console.log(doc2.data().globalBiometricEnabled);

              // globalBiometricEnabled == false, turns off the whole biometric system.
              // Users won't be prompted to authenticate via biometric (faceId/touchId)
              // even if the authentication has been set up.
              // Switch is found in firebase under globalBiometric collection

              if (
                doc.data().biometricEnabled === true &&
                doc2.data().globalBiometricEnabled === true
              ) {
                setIsStatus({
                  code: "BIOMETRIC_TIME_OUT",
                  timeOutAllowed: true,
                });
                navigate("/reauth");
              } else {
                setIsStatus({
                  code: "BIOMETRIC_DISABLED",
                  timeOutAllowed: false,
                  globalBiometricEnabled: false,
                });
                navigate("/");
              }
            });
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        // navigate("/");
        if (email == "" || password == "") {
          Swal.fire({
            icon: "error",
            title: "Please fill in the empty fields",
            confirmButtonColor: "#B3995B",
          });
        } else if (err.message == "Firebase: Error (auth/user-not-found).") {
          Swal.fire({
            icon: "error",
            title: "Please register an account with us.",
            confirmButtonColor: "#B3995B",
          });
        } else if (err.message == "Firebase: Error (auth/invalid-email).") {
          Swal.fire({
            icon: "error",
            title: "You've entered an invalid email.",
            confirmButtonColor: "#B3995B",
          });
        } else if (err.message == "Firebase: Error (auth/wrong-password).") {
          Swal.fire({
            icon: "error",
            title: "You've entered a wrong password.",
            confirmButtonColor: "#B3995B",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: err.message,
            confirmButtonColor: "#B3995B",
          });
        }
      });
  };

  return (
    <>
      <div>
        <div className="intro-header">
          <div
            style={{
              height: "20vh",
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src="/LogoStacked.png" alt="" width="200px" />
          </div>

          {/* <h2>Welcome to AUCOVA</h2>
          <h5>Jewellery Asset Management</h5> */}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "24px auto 40px",
          }}
        ></div>
        <div className="auth-container">
          <div className="auth">
            <input
              autoComplete="off"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label className={email ? "active" : ""}>Email</label>
          </div>
          <div className="auth">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label className={password ? "active" : ""}>Password</label>
          </div>

          <div className="forgot-password">
            <h5 onClick={() => setState(3)}>Forgot Password</h5>
          </div>

          <button className="intro-button btn-positive" onClick={() => logIn()}>
            {/* eslint eqeqeq: 0 */}
            {loading == true ? <BeatLoader size={8} /> : "Log In"}
          </button>

          <div className="auth-footer">
            <span>Not a member yet?</span>
            <span
              style={{
                textDecoration: "underline",
                color: "var(--primary)",
                paddingLeft: "5px",
              }}
              onClick={() => setState(2)}
            >
              Sign up
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default LogIn;
