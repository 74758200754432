import React, { useRef, useContext } from "react";
import { BeatLoader } from "react-spinners";
import { AuthContext } from "../../context/AuthContext";

function FileUploader({ loading, uploadFile, heicLoader, openUpload }) {
  // eslint-disable-next-line
  const { isStatus, setIsStatus } = useContext(AuthContext);

  // Detects if device is on IOS
  const isIos = () => {
    setIsStatus({
      ...isStatus,
      timeOutAllowed: false,
    });

    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  const filePickerRef = useRef(null);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className="file-uploader"
          name="items"
          onClick={(e) => {
            setIsStatus({
              ...isStatus,
              timeOutAllowed: false,
            });

            // If IOS, dont show uploadModal
            if (isIos()) {
              filePickerRef.current.click();
            } else {
              // If on Chrome, dont show uploadModal
              if (window.navigator.userAgent.match(/chrome|chromium|crios/i)) {
                filePickerRef.current.click();
              } else {
                openUpload(e);
              }
            }
          }}
        >
          {loading || heicLoader ? (
            <BeatLoader size={8} />
          ) : (
            <img src="/fixed/AddIcon.svg" alt="img" name="items" />
          )}
        </div>

        <input
          type="file"
          name="items"
          onChange={uploadFile}
          hidden
          ref={filePickerRef}
        />
      </div>
    </>
  );
}

export default FileUploader;
