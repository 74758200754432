import * as React from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Backdrop, Box } from "@mui/material";

const ITEM_HEIGHT = 48;

export default function LongMenuEntry({ handleSubmit, showMenu, setShowMenu }) {
  return (
    <div>
      <Backdrop
        style={{ zIndex: "99", color: "fff" }}
        open={showMenu}
        onClick={() => setShowMenu(false)}
      >
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 0, horizontal: "right" }}
          transformOrigin={{ vertical: 0, horizontal: "right" }}
          open={showMenu}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 6.5,
              width: "20ch",
              transform: "translateX(16px) translateY(-16px)",
              fontFamily: "Avenir Regular",
              fontSize: "16px",
            },
          }}
        >
          <MenuItem key="save" onClick={handleSubmit}>
            <h5>Save</h5>
          </MenuItem>

          <Box
            className="css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root"
            style={{
              backgroundColor: "#b3995b",
              fontFamily: "Avenir Bold",
              color: "white",
            }}
          >
            Publish To:
          </Box>

          <MenuItem style={{ whiteSpace: "normal" }} key="public" disabled>
            <h5>Public Showcase (Coming soon)</h5>
          </MenuItem>
          <MenuItem style={{ whiteSpace: "normal" }} key="marketplace" disabled>
            <h5>Marketplace (Coming soon)</h5>
          </MenuItem>
        </Menu>
      </Backdrop>
    </div>
  );
}
